import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Carousel, Col, Row } from "react-bootstrap";
import { addToCart, getCartData, removeAllFromCart, getOnsiteGoProd } from "../../api/Cart";
import { toast } from "react-toastify";
import { RWebShare } from "react-web-share";
import ElasticCarousel, { consts } from "react-elastic-carousel";
import ReactImageMagnify from "react-image-magnify";
import moment from "moment";
import Swal from "sweetalert2";

// MUI
import { Button, Grid, List, ListItem, Skeleton, TextField, styled } from "@mui/material";

//CSS
import "./ProductPage.css";
//ImagefederatedStock
// import offerIconYellow from "../../assets/vector/offers_icon_yellow.svg";

// import delivery_truck from "../../assets/productIcons/delivery_truck.svg";
// import credit_card from "../../assets/productIcons/credit_card.svg";
// import exchange from "../../assets/productIcons/exchange.svg";
// import cancellation_policy_icon from "../../assets/productIcons/cancellation_policy_icon.svg";
// import ribbon from "../../assets/productIcons/ribbon.svg";
// import days_replacement from "../../assets/productIcons/days_replacement.svg";

import guraentee from "../../assets/productIcons/guarantee.png";
import delivery_truck from "../../assets/productIcons/free-delivery.png";
import credit_card from "../../assets/productIcons/loan.png";
import cancellation_policy_icon from "../../assets/productIcons/cancel.png";
import exchange from "../../assets/productIcons/exchange.png";
import days_replacement from "../../assets/productIcons/return.png";

import OfferIcon from "../../assets/productIcons/OfferIcon.svg";
import EMIIcon from "../../assets/productIcons/EMIIcon.svg";
// import HDFC from "../../assets/png/hdfc.png";
// import SBI from "../../assets/png/SBI.png";
// import ICICI from "../../assets/png/ICICI.png";
import arrowRight from "../../assets/png/arrow_right.png";

// React-i18n
import { useTranslation } from "react-i18next";

//Components
import ProductInfoTable from "../../components/ProductInfoTable/ProductInfoTable";
/* import OfferCard from "../../components/OfferCard/OfferCard";
import AlternateProductBox from "../../components/AlternateProductCard/AlternateProductBox"; */
import {
  checkProdServiceabilityStatus,
  getProductRatingDetails,
  getProductServiceability,
  getProductStockQuantity,
  getSearchedProduct,
  storeProdNotificationService,
  getUsersSubProds,
  unsubsAProduct,
  suggestedProducts,
  getAlternateProductDetails,
  getSearchValuesForDynamicFilters,
  preBookProd,
  refundPreBookProd,
  getAllCouonTerms,
} from "../../api/Product";
import SkeletonElement from "../../components/Skeletons/SkeletonElement";
import { addToWishlist, deleteFromWishlist, getAllWishlistItems } from "../../api/wishlistApi";

import getMixedProducts from "../../hooks/getMixedProducts";
import useUserCtx from "../../hooks/useUserCtx";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useProductCtx from "../../hooks/useProductCtx";

// Utils
import { API_RESPONSE_STATUS, DEFAULT_GST_PERCENTAGE, OFFER_TYPES, PRODUCT_CLASSIFICATION } from "../../utils/Constants";
import { currencyFormat, isOfferActive } from "../../utils";

// MUI imports
import { Rating, useMediaQuery } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { IoShareSocial } from "react-icons/io5";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import CompareIcon from "@mui/icons-material/Compare";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
// import ProductKeyFeatureTable from "./ProductKeyFeatureTable";
import Review from "../../components/Review/Review";
import ProductCard from "../../components/Cards/ProductCard";
import Loader from "../../components/Loader/Loader";
import BasicModal from "./CarouselItemModal";
import loadingImg from "../../assets/png/loading.jpg";
import ExtendedWarrenty from "../../assets/onsiteGoPdf/EW.pdf";
import spillDrop from "../../assets/onsiteGoPdf/SDP.pdf";
import screenPlanProtection from "../../assets/onsiteGoPdf/SP.pdf";
import screenprotection from "../../assets/png/screenprotection.png";
import spill from "../../assets/png/spill.png";
import warranty from "../../assets/png/warranty.png";
import extendedWarrentyLogo from "../../assets/png/2yearslogo.png";
import SEO from "../../components/SEO/SEO";
import useSiteCtx from "../../hooks/useSiteCtx";
import TermsAndCondtionModal from "../../components/TermsAndCondtionModal";

// toast.configure();

const customArrow = ({ type, onClick, isEdge }) => {
  const pointer =
    type === consts.PREV ? (
      <KeyboardArrowLeftIcon className="h-100 cursor-pointer" onClick={onClick} disabled={isEdge} />
    ) : (
      <KeyboardArrowRightIcon className="h-100 cursor-pointer" onClick={onClick} disabled={isEdge} />
    );

  return pointer;
};

// Component definition
const ProductPage = () => {
  const { allProducts, payuOffers } = useProductCtx();
  const {
    setUserLocation,
    setUserSubscribed,
    setUserPreBooked,
    userPreBooked,
    userContext,
    cartArray,
    setCartArray,
    setUserDefaultAddress,
    userZip,
    setUserZip,
    setPaymentProductArr,
    setOrderInit,
    userWishlist,
    setUserWishlist,
    userSubscribed,
  } = useUserCtx();
  const { width } = useWindowDimensions();
  const [emptyOrdersProductArray, setEmptyOrdersProductArray] = useState([]);
  const { t } = useTranslation();
  const nav = useNavigate();
  const { slug } = useParams();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 1000px)");
  const [reviewDetails, setReviewDetails] = useState({
    ratingAvg: 0,
    totalReviews: 0,
    totalSolds: 0,
  });
  const productDescref = useRef(null);

  // state definitions
  const [preOrder] = useState(false);
  const [previewImageSelected, setPreviewImageSelected] = useState({ image: null, index: null });
  const [productInfo, setProductInfo] = useState([]);
  const [productSecondData, setProductSecondData] = useState({});
  const [productData, setProductData] = useState({
    product_loaded: false,
    product_L1: "Consumer Electronics",
    product_L2: "",
    product_L3: "",
    product_Classification: "",
    product_Id: "",
    product_Ean: "",
    product_Heading: "",
    product_name: "",
    product_color: "",
    product_image: "",
    product_price: {
      mrp: "",
      mop: "",
      discountPrice: "",
    },
    product_Discount: {},
    offer_Deadline: "Deal ends in 14h 17m 04s",
    product_Instock: 0,
    product_image_List: [],
    product_Gallery_Image: [],
    product_Description: [],
    product_brand: "",
    gstpercentage: DEFAULT_GST_PERCENTAGE,
    warranty: "",
    from: [],
    preBookAmount: 0,
  });
  const [alternateColorean, setAlternateColorean] = useState([]);
  const [alternateSpecean, setAlternateSpecean] = useState([]);
  const [, setProductBankOffers] = useState([]);
  const [discountPercent, setDiscountPercent] = useState("");
  const [comboProductData, setComboProductData] = useState({});
  const [allOffersData, setAllOffersData] = useState([]);
  const [discountTillDate, setDiscountTillDate] = useState(null);
  const [days, setDays] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [seconds, setSeconds] = useState("");
  const [deliveryEstDays, setDeliveryEstDays] = useState({
    loaded: false,
    value: "",
  });
  const [, setProductPageSuggestProd] = useState([]);
  // const [enterPinClicked, setEnterPinClicked] = useState(false);
  const [pincode, setPincode] = useState("");
  const [validLength, setValidLength] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [federatedStock, setFederatedStock] = useState(0);
  const [stockLoading, setStockLoading] = useState(false);
  const [isProdServiceable, setIsProdServiceable] = useState(false);
  const [thisProdSubs, setThisProdSubs] = useState(false);
  const [preBookedId, setPreBookedId] = useState();
  const [thisProdPreBooked, setThisProdPreBooked] = useState(false);
  const [preBookedAmount, setPreBookedAmount] = useState(0);

  const [alternateColorHover, setAlternateColorHover] = useState({
    isHover: false,
    alternateColorText: "",
    originalColor: "",
  });
  const [alternateColorLoading, setAlternateColorLoading] = useState(true);
  const { setFilterSelected, filterSelected } = useUserCtx();
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [onsitegoData, setOnsitegoData] = useState([]);
  const [onsiteGoSelected, setOnsiteGoSelected] = useState([]);
  const [walletOffers, setWalletOffers] = useState([]);

  const [show, setShow] = useState(false);
  const [termData, setTermData] = useState({});
  const { seoData } = useSiteCtx();

  // Computed
  const price = productData.product_price;
  // const gstpercentage = productData?.gstpercentage ?? DEFAULT_GST_PERCENTAGE;
  const productMRP = currencyFormat(price?.mrp);

  useEffect(() => {
    const ean = productData.product_Ean;
    if (productData.product_Classification !== PRODUCT_CLASSIFICATION.COMING_SOON || (productData.product_Classification !== PRODUCT_CLASSIFICATION.PRE_BOOKED && userZip.value && ean)) {
      checkThisProductServiceability(userZip.value, ean);
    }
  }, [userZip.value, productData.product_Ean]);

  // add payu offers to all offers data

  // useEffect(() => {
  // setTimeout(() => {
  //   setAllOffersData((prev) => [...prev, ...payuOffers]);
  // }, 50);
  // }, [payuOffers]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const { notifyUser = false, productId } = location.state ?? {};
    if (notifyUser && productId) {
      setTimeout(() => {
        storeProductNotification(productId);
      }, 0);
    }
  }, []);

  useEffect(() => {
    suggestedProducts().then((res) => {
      setEmptyOrdersProductArray(res.data?.status === "success" ? res.data.productsWithRatings : []);
    });
  }, []);

  useEffect(() => {
    if (!productData?.product_Id) return;
    const fetchData = async () => {
      // setAlternateColorLoading(true);
      try {
        const response = await getAlternateProductDetails(productData?.product_Id);
        if (response?.data?.status === "success") {
          setAlternateColorean(response?.data?.totalUniqueColorObj ?? []);
          setAlternateSpecean(response?.data?.specs ?? []);
          var categoryArray = response?.data?.categoryArray;
          Object.entries(productData.productInfo).forEach(([fieldName]) => {
            if (categoryArray.includes(fieldName)) {
              const originalValues = Object.entries(productData.productInfo)
                .filter(([key]) => categoryArray.includes(key))
                .map(([_, val]) => val?.toString()?.trim())
                .join(" / ");

              const reversedValues = originalValues
                .split(" / ")
                .map((val) => val.trim())
                .reverse()
                .join(" / ");

              productData.value = reversedValues;
            }
          });
          productData.product_loaded = true;
          setProductData((prev) => ({
            ...prev,
            ...productData,
          }));
        } else {
          toast.error("Something went wrong in Fetching Products.");
        }
      } catch (error) {
        toast.error("Oops! Something went wrong");
      } finally {
        // setAlternateColorLoading(false);
      }
    };

    fetchData();

    // return () => {
    //   setAlternateColorean([]);
    //   setAlternateSpecean([]);
    // };
  }, [productData?.product_Id]);
  useEffect(() => {
    getCoupnTerms();
  }, []);

  const getCoupnTerms = async () => {
    const data = await getAllCouonTerms();
    if (data.status === 200) {
      setTermData(data.data);
    }
  };

  useEffect(() => {
    setProductBankOffers([]);
    getSearchedProduct("slug=" + slug).then((res) => {
      const updatedCoupons = res.coupons.map((coupon) => ({ ...coupon, appliedStatus: false }));
      setOnsitegoData(res?.onsiteGoplan);
      setWalletOffers(updatedCoupons);
      let product = res.products[0];
      if (!product || product.classification === PRODUCT_CLASSIFICATION.TEMP_HIDDEN) {
        nav("/not-found");
        return;
      }
      let images = product?.images ?? [];
      if (product) product["quantity"] = 1;
      product.from = location.state ? [location.state?.brand_store_id] : [];
      setProductSecondData(product);

      //Product Heading
      let heading = product.title;
      const size = [product?.size];
      size.forEach((e) => {
        if (e) heading += ` - ${e}`;
      });

      //Split the Description by \n (newline) if it contains in array or just by .(period)
      let array = [];
      let string = product.description[0];
      if (string.includes("\n")) {
        array = string.split("\n");
      } else {
        array = string.split(/\.(?!\d)/);
      }
      //Filter description it contains null or empty strings
      let filteredArray = array.filter((str) => str.trim() !== "");
      let prebookedAmount = 0;
      if (userPreBooked.length > 0) {
        prebookedAmount = userPreBooked.filter((el) => el.productId === product._id).map((el) => el.amount);

        if (product.price && product.price.mop != null) {
          product.price.mop -= prebookedAmount;
        }
      }

      const productData = {
        product_loaded: true,
        product_L1: "Consumer Electronics",
        product_L2: product.hierarchyL2,
        product_L3: product.hierarchyL3,
        product_Classification: product.classification,
        product_Id: product._id,
        product_Ean: product.ean,
        product_Heading: heading,
        product_name: product.title,
        product_color: product.color || product.title?.toString()?.split("-")?.pop()?.trim(),
        product_price: product.price,
        product_Description: filteredArray,
        product_image_List: images,
        product_Gallery_Image: [...product.gallery],
        product_Discount: product.discount,
        product_Slug: product.slug,
        product_Instock: product.qty,
        product_brand: product.brand,
        gstpercentage: product?.gstpercentage ?? DEFAULT_GST_PERCENTAGE,
        warranty: product?.productInfo?.warranty,
        productInfo: product?.productInfo,
        preBookAmount: product?.preBook?.amount ?? 0,
        isRefundable: product?.preBook?.isRefundable ?? 0,
        _id: product?._id,
      };
      setProductData((prev) => ({
        ...prev,
        ...productData,
      }));

      const productInfo1 = product.productInfo;
      if (productInfo1) {
        const arr = { weight: productInfo1["weight"] ? productInfo1["weight"] : product?.weight };

        const filteredObject = Object.entries(productInfo1).filter(function (item) {
          return item[0] !== "weight" && item[0] !== "serial_no";
        });
        setPreviewImageSelected((prev) => ({ ...prev, image: images[0], index: 0 }));
        product.productInfo && setProductInfo((prev) => [...prev, ...filteredObject, ...Object.entries(arr)]);
        setAllOffersData(product.offers);

        setAlternateColorHover((prev) => ({ ...prev, originalColor: productData.product_color }));
      }
    });
  }, [slug, userPreBooked]);

  useEffect(() => {
    if (productData.product_Ean) {
      fetchProductStockDetails(productData.product_Ean);
    }
    if (productData?.product_Id) {
      fetchRatingDetails(productData.product_Id);
    }
  }, [productData?.product_Ean]);

  useEffect(() => {
    setProductPageSuggestProd(getMixedProducts(allProducts.products, allProducts.np1, 10));
  }, [allProducts]);

  let interval;
  useEffect(() => {
    // initiates flat discount timer
    if (productData?.product_Discount?.flatDiscount?.value > 0 && isOfferActive(productData?.product_Discount?.flatDiscount)) {
      // setDiscountPercent(productData.product_Discount.flatDiscount.value);
      let discountToDate = new Date(productData.product_Discount.flatDiscount.to);
      interval = setInterval(() => {
        startTimer(discountToDate);
      }, 1000);
    }

    // initiates combo timer
    if (productData && productData?.product_Discount?.combo && productData?.product_Discount?.combo?.value) {
      const discountToDate = new Date(productData?.product_Discount?.combo?.to);
      interval = setInterval(() => {
        startTimer(discountToDate);
      }, 1000);
    }

    let mrp = parseFloat(productData?.product_price.mrp);
    let mop = parseFloat(productData?.product_price.mop);

    let discount = Math.round(((mrp - mop) / mrp) * 100);

    // if (discount > 0.5) {
    //   discount = Math.ceil(discount);
    // } else {
    //   discount = Math.floor(discount);
    // }

    setDiscountPercent(discount);
    if (productData?.product_Discount?.combo?.value && isOfferActive(productData?.product_Discount?.combo)) {
      setDiscountTillDate(productData?.product_Discount?.combo?.value);
    } else {
      setDiscountTillDate(null);
    }

    return () => {
      clearInterval(interval);
    };
  }, [productData]);

  useEffect(() => {
    if (userZip?.loaded && productData.product_loaded) {
      let prodArray = [
        {
          skuId: productData.product_Ean,
          quantity: 1,
        },
      ];

      getProductServiceability(userZip.value, prodArray).then((res) => {
        if (res) {
          if (res[0].deliverymodes.length > 0) {
            let del = res[0].deliverymodes[0];
            let delTime = del.deliveryTime;
            let delTimeInDays = Math.floor(delTime / 24);
            setDeliveryEstDays({
              loaded: true,
              value: delTimeInDays,
            });
          }
        }
      });
    }
  }, [productData, userZip]);

  const startTimer = (date) => {
    const countDownDate = new Date(moment(date).add(1, "days")).getTime();
    const now = new Date().getTime();
    const dist = countDownDate - now;

    let days = Math.floor(dist / (24 * 60 * 60 * 1000));
    let hours = Math.floor((dist % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60));
    let minutes = Math.floor((dist % (60 * 60 * 1000)) / (1000 * 60));
    let seconds = Math.floor((dist % (60 * 1000)) / 1000);
    if (days < 0) {
      days = 0;
    }
    if (hours < 0) {
      hours = 0;
    }
    if (minutes < 0) {
      minutes = 0;
    }
    if (seconds < 0) {
      seconds = 0;
    }
    if (dist < 0) {
      //stop timer
      clearInterval(interval.current);
      setDiscountTillDate(null);
    } else {
      // Update Timer
      setDays(days < 10 ? `0${days}` : days);
      setHours(moment().hour(hours).format("HH"));
      setMinutes(moment().minute(minutes).format("mm"));
      setSeconds(moment().second(seconds).format("ss"));
      setDiscountTillDate(date);
    }
  };

  useEffect(() => {
    if (productData.product_loaded && productData?.product_Discount && productData?.product_Discount?.combo?.value && isOfferActive(productData?.product_Discount?.combo)) {
      let proId = productData.product_Discount.combo.childEan;
      let searchTerm = "ean=" + proId;
      getSearchedProduct(searchTerm).then((res) => {
        if (res && res.products) {
          setComboProductData(res?.products[0]);
        }
      });
    }
  }, [productData]);

  useEffect(() => {
    if (comboProductData && isOfferActive(productData?.product_Discount?.combo)) {
      if (Object.keys(comboProductData).length > 0) {
        let offerId = comboProductData._id;
        let offerHeading = "Buy one Get one";
        let offerName = `Get ${comboProductData.title} free on Purchase of ${productData.product_name}`;
        let offerAvail = "Select eligible card at the time of checkout.~No promo code required to avail the offer.~New Desc,~New DEsc";
        const offerType = "Combo Offer";

        let combo_Offer = {
          offerId: offerId,
          offerHeading: offerHeading,
          offerName: offerName,
          offerAvail: offerAvail,
          offerType,
        };
        if (allOffersData) {
          let offerLen = allOffersData.findIndex((obj) => obj.offerId === combo_Offer.offerId);
          if (offerLen === -1) {
            setAllOffersData([...allOffersData, combo_Offer]);
          }
        }
      }
    }
  }, [comboProductData]);

  // Component did mount
  // useEffect(() => {
  //   fetchUserSubscribedProds();
  // }, []);

  // ComponentDidMount / ComponentDidUpdate
  useEffect(() => {
    let subsresult = false;
    let prebookresult = false;
    let preBookId;
    if (userSubscribed.length > 0) {
      subsresult = userSubscribed.some((el) => el.productId === productData.product_Id);
    }
    if (userPreBooked.length > 0) {
      prebookresult = userPreBooked.some((el) => el.productId === productData.product_Id);
      preBookId = userPreBooked.find((el) => el.productId === productData.product_Id)?.prebookOrderId;
    }
    setPreBookedId(preBookId);
    setThisProdSubs(subsresult);
    setThisProdPreBooked(prebookresult);
  }, [userSubscribed, productData.product_Id, userPreBooked]);

  const handleLength = (length) => {
    if (length === 5) {
      setValidLength(true);
    } else {
      setValidLength(false);
    }
  };

  const validateNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPincode(e.target.value);
    }
  };

  const formSubmit = (e) => {
    e.preventDefault();
    if (pincode !== "") {
      setUserLocation((prev) => ({
        ...prev,
        loaded: true,
        useThis: true,
        address: { city: "", state: "", zip: pincode },
      }));
      setUserDefaultAddress((prev) => ({
        ...prev,
        loaded: false,
        useThis: false,
      }));
    }
  };

  const validateForm = () => (pincode !== "" && validLength ? setBtnDisable(false) : setBtnDisable(true));

  const handleQuantityInc = () => {
    const currentQuantity = productSecondData.quantity;
    if (currentQuantity < federatedStock) {
      setProductSecondData({
        ...productSecondData,
        quantity: currentQuantity + 1,
      });
    }
  };

  const handleQuantityDec = () => {
    if (productSecondData.quantity > 1)
      setProductSecondData({
        ...productSecondData,
        quantity: productSecondData.quantity - 1,
      });
  };

  const setCartFunc = () => {
    getCartData().then((res) => {
      if (res) {
        let prod = [];
        res.cart.forEach((item) => {
          if (item?.productId === null) return;
          let product = { ...item?.productId };
          product["quantity"] = item.qty;
          product["from"] = item.from;
          product["coupon"] = item.couponId ? item.couponId : {};
          prod.push(product);
        });
        setCartArray({
          loaded: true,
          no_of_carts: prod.length,
          cart: prod,
          combo: res.combo,
        });
      }
    });
  };

  const getStoreIdAndType = useCallback(() => {
    let { brand_store_id, type } = location?.state ? location.state : { brand_store_id: "", type: "" };
    return { brand_store_id, type };
  }, [location]);

  const handleAddToCart = (id) => {
    let userToken = userContext ? userContext.JWT : "";
    if (userToken) {
      const productQuantity = productSecondData.quantity;
      const { brand_store_id, type } = getStoreIdAndType();
      const appliedCouponId = walletOffers.find((offer) => offer.appliedStatus)?._id ?? "";
      const cartArr = cartArray.cart;
      for (const i in cartArr) {
        if (cartArr[i]?.coupon._id === appliedCouponId) {
          toast.warning("Coupon Alredy Added in Cart!");
          return;
        }
      }
      addToCart({ id, qty: productQuantity, brand_store_id, onsiteGoSelected: selectedSkus, appliedCouponId }).then((res) => {
        if (res?.data?.status === "error") {
          if (window.confirm(res.data.message)) {
            removeAllFromCart().then((response) => {
              if (response) {
                setCartFunc();
                setOrderInit((prev) => ({
                  ...prev,
                  type,
                  brand_store_id,
                }));
                toast.success(t("manualAddToCart"));
              }
            });
          }
        } else {
          toast.success(t("product-page.added-to-cart"));
          setCartFunc();
        }
      });
    } else {
      if (selectedSkus) {
        toast.warning("Please Login to Add Onsite Go Benifits to Cart!");
        setOnsiteGoSelected(0);
        setSelectedSkus([]);
      }
      let updatedCartArr;

      const prevCartArr = JSON.parse(localStorage.getItem("cart"));

      let isAlreadyAdded = false;
      if (prevCartArr.cart) {
        prevCartArr.cart.forEach((prod) => {
          if (prod.product_Ean === productData.product_Ean) {
            isAlreadyAdded = true;
            return;
          }
        });
      }
      if (!isAlreadyAdded) {
        productData.quantity = productSecondData.quantity;
        productData.from = [];
        updatedCartArr = {
          ...prevCartArr,
          cart: [...prevCartArr.cart, productData],
          no_of_carts: prevCartArr.no_of_carts + 1,
          loaded: true,
        };
        // Save the updated prevCartArr back to local storage
        localStorage.setItem("cart", JSON.stringify(updatedCartArr));

        setCartArray(() => ({
          loaded: true,
          no_of_carts: updatedCartArr.cart.length,
          cart: updatedCartArr.cart,
          combo: [],
        }));
      }
    }
  };

  const isAddedToWishlist = userWishlist.wishlist_items?.some((obj) => obj._id?.toString() === productData.product_Id?.toString());

  const handleAddToWishlist = (id) => {
    let userToken = userContext ? userContext?.JWT : "";
    if (userToken) {
      if (!isAddedToWishlist) {
        addToWishlist(id).then((res) => {
          if (res.status === 200) {
            toast.success(t("product-page.added-to-wishlist"));
            getAllWishlistItems(true).then((response) => {
              if (response)
                setUserWishlist((prev) => ({
                  ...prev,
                  loaded: true,
                  no_of_wishlist_items: response?.no_of_wishlist_items,
                  wishlist_items: response?.wishlist_items,
                }));
            });
          } else toast.error(t("addProductInWishlistError"));
        });
      } else {
        deleteFromWishlist(id).then((res) => {
          if (res.status === 200) {
            toast.error(t("notifications.ProductRemovedFromWishlist"));
            getAllWishlistItems(true).then((response) => {
              if (response)
                setUserWishlist((prev) => ({
                  ...prev,
                  loaded: true,
                  no_of_wishlist_items: response?.no_of_wishlist_items,
                  wishlist_items: response?.wishlist_items,
                }));
            });
          } else toast.error(t("deleteProductInWishlistError"));
        });
      }
    } else {
      nav("/login");
    }
  };

  const handleOrderInit = async (e) => {
    e.preventDefault();
    let userToken = userContext ? userContext.JWT : "";
    if (userToken) {
      let { brand_store_id, type } = location.state ? location.state : { brand_store_id: "", type: "" };
      let productId = [productData.product_Id];
      let quantity = [1];
      let onsiteGoPlan = [];

      let paymentProducts = [];

      // Find the applied coupon for the product

      const appliedCoupon = walletOffers.find((offer) => offer.appliedStatus);

      // Add the product data to the paymentProducts array
      paymentProducts.push({
        ...productSecondData,
        coupon: appliedCoupon, // Add the applied coupon data
      });

      if (selectedSkus && Array.isArray(selectedSkus) && selectedSkus.length > 0) {
        // Iterate through each selected SKU
        const promises = selectedSkus.map(async (selectedSku) => {
          try {
            // Find the corresponding plan data for the selected SKU
            const planData = onsitegoData.find((item) => item.onsitego_sku === selectedSku);

            // Await the result of getOnsiteGoProd for each SKU
            const res = await getOnsiteGoProd(selectedSku);

            if (res) {
              res.plan.quantity = productSecondData.quantity;
              res.plan.from = {
                product: productData.product_Id,
                plan: selectedSku,
                planAmount: planData.unit_price,
                planTitle: planData.productType,
              };
              paymentProducts.push(res.plan);
            }
            return res.plan;
          } catch (err) {
            console.log("Error fetching onsitego product:", err);
            // Handle the error
            return null;
          }
        });
        Promise.all(promises)
          .then((results) => {
            // Proceed with setting state and navigation
            setOrderInit((prev) => ({
              ...prev,
              productId: productId,
              quantity: quantity,
              brand_store_id,
              onsiteGoPlan,
              type,
              appliedCoupon: appliedCoupon,
            }));
            console.log("paymentProducts ", paymentProducts);

            localStorage.setItem("paymentProductArr", JSON.stringify({ loaded: true, cart: paymentProducts, combo: [] }));
            setPaymentProductArr((prev) => ({
              ...prev,
              loaded: true,
              cart: paymentProducts,
              onsiteGoPlan,
              combo: [],
            }));
            nav("/payment");
          })
          .catch((error) => {
            console.error("Error waiting for promises:", error);
            // Handle the error
          });
      } else {
        setOrderInit((prev) => ({
          ...prev,
          productId: productId,
          quantity: quantity,
          brand_store_id,
          onsiteGoPlan,
          type,
        }));
        localStorage.setItem("paymentProductArr", JSON.stringify({ loaded: true, cart: paymentProducts, combo: [] }));
        setPaymentProductArr((prev) => ({
          ...prev,
          loaded: true,
          cart: paymentProducts,
          onsiteGoPlan,
          combo: [],
        }));
        nav("/payment");
      }
    } else {
      nav("/login");
    }
  };

  const handleApplyCoupon = (index) => {
    setWalletOffers((prevWalletOffers) =>
      prevWalletOffers.map((offer, i) => {
        if (i === index) {
          return { ...offer, appliedStatus: !offer.appliedStatus }; // toggle appliedStatus
        } else {
          return { ...offer, appliedStatus: false }; // preserve appliedStatus of other coupons
        }
      })
    );
  };

  const notifyUserInit = () => {
    const userToken = userContext ? userContext.JWT : "";
    const { product_Id, product_Slug } = productData;

    // product already subscribed
    if (thisProdSubs) {
      // remove product from user subscription list
      console.log("userProdUnsubs ", product_Id);
      userProdUnsubs(product_Id);
    } else {
      if (!userToken) {
        nav("/login", {
          state: {
            productId: product_Id,
            notifyUser: true,
            productSlug: product_Slug,
          },
        });
      } else {
        console.log("store Product notification");
        storeProductNotification(product_Id);
      }
    }
  };

  const preeBookOrder = async () => {
    if (!userContext?.JWT) {
      nav("/login");
      return;
    }
    if (thisProdPreBooked && !productData?.isRefundable) return;
    if (thisProdPreBooked && productData?.isRefundable) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const refundRes = await refundPreBookProd(preBookedId);
          console.log("refundRes ", refundRes);
          if (refundRes.status === "success") {
            toast.success("Pre-Book Order has been Cancel!");
            setThisProdPreBooked(false);
          }
          return;
          // Swal.fire({
          //   title: "Deleted!",
          //   text: "Your file has been deleted.",
          //   icon: "success
          // });
        }
      });
    } else {
      const { product_Id } = productData;
      try {
        const response = await preBookProd(product_Id);
        window.open(response, "_parent");
        // if (payload.status === "success") {
        //   toast.success("Product Subscribed!");
        //   fetchUserSubscribedProds();
        // }
      } catch {
        // handle error
      }
    }
  };
  const storeProductNotification = async (productId) => {
    try {
      const response = await storeProdNotificationService(productId);
      const payload = response.data;

      if (payload.status === API_RESPONSE_STATUS.SUCCESS) {
        toast.success(payload.message);
        fetchUserSubscribedProds();
      } else {
        toast.error(payload.message);
      }
      // reset history
      window.history.replaceState({}, document.title);
    } catch {
      toast.error(t("writeToUs.error"));
    }
  };

  const fetchUserSubscribedProds = async () => {
    if (!userContext?.JWT) return;

    try {
      const response = await getUsersSubProds();
      const payload = response.data;
      if (payload.status === "success") {
        setUserSubscribed(payload?.subscribedProducts ?? []);
        setUserPreBooked(payload?.preBooked ?? []);
      }
    } catch {
      // handle error
    }
  };

  const getFinalDisplayPrice = (productData) => {
    const mop = parseFloat(productData.product_price?.mop);
    if (!mop) return 0;
    const flatDiscount = productData.product_Discount?.flatDiscount;

    let result = mop;

    if (flatDiscount?.value > 0 && isOfferActive(flatDiscount)) {
      if (flatDiscount?.discountType === "flat") {
        result -= flatDiscount?.value;
      } else {
        result -= (result * flatDiscount?.value) / 100;
      }
    }
    return result;
  };

  const fetchProductStockDetails = async (ean) => {
    setStockLoading(true);
    const response = await getProductStockQuantity(ean);
    const payload = response.data;
    if (payload.status === "success") {
      const stocks = payload.data.reduce((acc, curr) => {
        return acc + curr.quantity;
      }, 0);

      setFederatedStock(stocks);
      setStockLoading(false);
    }
  };

  const fetchRatingDetails = async (id) => {
    try {
      const response = await getProductRatingDetails(id);
      if (response?.data?.status === "success") {
        setReviewDetails((prev) => ({ ...prev, ...response.data?.data }));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? t("writeToUs.error"));
    }
  };

  const checkThisProductServiceability = async (pincode, ean) => {
    if (pincode && ean) {
      const item = {
        ean: productData.product_Ean,
        title: productData.product_name,
        quantity: 1,
      };

      const response = await checkProdServiceabilityStatus(item, pincode);
      const payload = response.data;
      if (payload.status === "success") {
        setIsProdServiceable(true);
      } else {
        toast.error(t("productNotServicable"));
      }
    }
  };

  const setUserPincode = () => {
    const value = document.getElementById("userzip").value;
    if (value) {
      setUserZip({
        loaded: true,
        value,
      });
    }
  };

  const getComboProdPrice = (comboProdMop, comboOffer) => {
    let result = comboProdMop;
    const discountVal = parseFloat(comboOffer.value);

    if (comboOffer.discountType !== "flat") {
      result = result - (result * discountVal) / 100;
    } else {
      result = result - discountVal;
    }
    return result;
  };

  // invokes API to remove this product from users' subsription list
  const userProdUnsubs = async (prodId) => {
    try {
      const response = await unsubsAProduct(prodId);
      const payload = response.data;
      if (payload.status === "success") {
        toast.success("Product Unsubscribed!");
        fetchUserSubscribedProds();
      }
    } catch {
      // handle error
    }
  };

  /** Handler functions - ends */

  // Computed Properties
  const isProductOutOfStock = productData.product_Instock < 1;
  const BankArray = [1, 2, 3, 4];
  const isComingSoonProd = productData.product_Classification === PRODUCT_CLASSIFICATION.COMING_SOON;
  const isPreBookedProd = productData.product_Classification === PRODUCT_CLASSIFICATION.PRE_BOOKED;

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#5A99CD",
    },
    "& .MuiRating-iconHover": {
      color: "#5A99CD",
    },
  });

  const inCart = cartArray?.no_of_carts > 0 && cartArray?.cart.some((obj) => obj?._id?.toString() === productData?.product_Id?.toString());

  const handleClose = () => setOpen(false);

  const openModal = (image) => {
    setOpen(true);
    if (isMobile) {
      setSelectedImage(image);
    }
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const [selectedSkus, setSelectedSkus] = useState([]);

  useEffect(() => {
    if (productData.product_L2 === "Smartphone") {
      setSelectedSkus(process.env.REACT_APP_BASE_URL === "https://alpha-api.olineo.in/api" ? [18588] : [3507]);
      // setSelectedSkus([]);
    } else {
      setSelectedSkus([]);
    }
  }, [productData]);

  const handleOnsiteGoChange = (e, item) => {
    const isSelected = e.target.checked;

    if (item.productType === "Extended Warranty") {
      if (isSelected) {
        setSelectedSkus((prev) => [...prev, item.onsitego_sku]);
      } else {
        setSelectedSkus((prev) => prev.filter((sku) => sku !== item.onsitego_sku));
      }
    } else if (item.productType === "Spills and Drops / Damage Protection" || item.productType === "Screen Protection" || item.productType === "Add Onsitego Screen Protection") {
      if (isSelected) {
        setSelectedSkus((prev) => {
          // Ensure only one item from this category is selected at a time
          const filtered = prev.filter(
            (sku) =>
              !onsitegoData.find(
                (i) =>
                  i.onsitego_sku === sku && (i.productType === "Spills and Drops / Damage Protection" || i.productType === "Screen Protection" || i.productType === "Add Onsitego Screen Protection")
              )
          );
          return [...filtered, item.onsitego_sku];
        });
      } else {
        setSelectedSkus((prev) => prev.filter((sku) => sku !== item.onsitego_sku));
      }
    }
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  return (
    <>
      <SEO seoData={seoData} />
      {open && <BasicModal open={open} handleClose={handleClose} image={selectedImage} />}
      <div className="page_Wrapper bg-white px-lg-5">
        {productData?.product_loaded ? (
          <>
            <div className="slug-URL-Container">
              <span className="slug-URL">
                {/* {productData.product_L3} */}
                <Link className="slugLink" to="/">
                  {productData.product_L1}
                </Link>
                {productData.product_L1 && " > "}
                <Link className="slugLink" onClick={() => setFilterSelected([])} to={`/Category=${productData.product_L2}`}>
                  {productData.product_L2.charAt(0).toUpperCase() + productData.product_L2.slice(1)}
                </Link>
                {productData.product_L2 && " > "}
                <Link className="slugLink" to={`/brand/${productData.product_brand}?hierarchyL2=${productData.product_L2}`}>
                  {productData.product_brand.charAt(0).toUpperCase() + productData.product_brand.slice(1)}
                </Link>
                {productData.product_brand && " > "}
                <span style={{ color: "black" }}>{productData.product_name}</span>
              </span>
            </div>
            <div className="desk_Page_Wrapper d-flex product-flex-container mb-4" style={{ gap: isMobile ? "0" : "3.5rem" }}>
              <aside className="side_Section section_Wrapper product_Side_Section border-0 product-child-1 h-100 m-0 p-0">
                {!isMobile && (
                  <div className="d-flex justify-content-start" style={{ gap: "10px", height: "22px" }}>
                    <span className="tagList" onClick={() => handleAddToWishlist(productData.product_Id)}>
                      <StyledRating
                        name="wishlist"
                        title={isAddedToWishlist ? t("removeFromWishlist") : t("product-page.add-to-wishlist")}
                        max={1}
                        className="add_to_wishlist_icon"
                        defaultValue={isAddedToWishlist ? 1 : 0}
                        icon={<FavoriteIcon fontSize="inherit" />}
                        emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                        style={{
                          pointerEvents: "none",
                          userSelect: "none",
                          fontSize: "1.1rem",
                        }}
                      />
                      <span className="add_to_wishlist_text">{!isAddedToWishlist ? t("product-page.add-to-wishlist") : t("removeFromWishlist")}</span>
                    </span>
                    <RWebShare
                      data={{
                        text: productData.product_Description ?? "",
                        url: window.location.href,
                        title: productData.product_Heading,
                      }}
                    >
                      <button className="tagList border-0 bg-transparent" style={{ fontSize: "12px", fontWeight: "500" }}>
                        <span className="add_to_wishlist_text">
                          {/* <ShareIcon style={{ fontSize: "1.1rem", width: "25px" }} className="product_share_icon" /> */}
                          <IoShareSocial style={{ fontSize: "1.1rem", width: "25px" }} className="product_share_icon" />
                        </span>
                        <span className="add_to_wishlist_text" style={{ marginTop: "3px" }}>
                          {t("product-page.share")}
                        </span>
                      </button>
                    </RWebShare>
                  </div>
                )}
                <div className="image_Preview_Side_Section" style={{ justifyContent: "space-between" }}>
                  <div className="image_Preview_Selected section_Wrapper magnify-image" style={{ border: "1px solid #707070" }}>
                    {productData.product_loaded === false ? (
                      <SkeletonElement type={"productPreviewImage"} />
                    ) : (
                      <ReactImageMagnify
                        className="w-100 cursor-zoom-in"
                        lensComponent={<IoShareSocial />}
                        enlargedImageClassName="z-2 bg-white"
                        {...{
                          enlargedImagePosition: "over",
                          smallImage: {
                            alt: "Product Image",
                            src: alternateColorHover.isHover
                              ? alternateColorean.find((ele) => ele?.colorCode === alternateColorHover.alternateColorText)?.image[previewImageSelected.index] ?? previewImageSelected.image
                              : previewImageSelected.image,
                            width: "100%",
                            height: "100%",
                            isFluidWidth: true,
                          },
                          largeImage: {
                            src: alternateColorHover.isHover
                              ? alternateColorean.find((ele) => ele?.colorCode === alternateColorHover.alternateColorText)?.image[previewImageSelected.index] ?? previewImageSelected.image
                              : previewImageSelected.image,
                            width: 900,
                            height: 900,
                          },
                        }}
                      />
                    )}
                  </div>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#000000",
                    }}
                  >
                    {t("zoomInImage")}
                    <ZoomInIcon
                      style={{
                        marginLeft: "10px",
                      }}
                    />
                  </span>
                  <div className="product_Thumbnails d-flex flex-row flex-wrap w-100 mt-4">
                    {productData.product_loaded === false ? (
                      [1, 2, 3, 4, 5].map((n) => <SkeletonElement type={"productThumbnail"} key={n} />)
                    ) : (
                      <>
                        {productData.product_image_List.map((image, index) => (
                          <div
                            style={{
                              border: "1px solid #707070",
                            }}
                            className="thumbnail"
                            key={index}
                            onMouseOver={() => setPreviewImageSelected((prev) => ({ ...prev, image, index }))}
                          >
                            <img src={image} alt="image1" />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </aside>
              {isMobile && (
                <div className="d-flex justify-content-end align-self-end" style={{ gap: "10px" }}>
                  <span className="tagList" onClick={() => handleAddToWishlist(productData.product_Id)}>
                    <StyledRating
                      name="wishlist"
                      title={isAddedToWishlist ? t("removeFromWishlist") : t("product-page.add-to-wishlist")}
                      max={1}
                      className="add_to_wishlist_icon"
                      defaultValue={isAddedToWishlist ? 1 : 0}
                      icon={<FavoriteIcon fontSize="inherit" />}
                      emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                      style={{
                        pointerEvents: "none",
                        userSelect: "none",
                      }}
                    />
                    <span style={{ marginTop: "2px", marginLeft: "2px" }}>{!isAddedToWishlist ? t("product-page.add-to-wishlist") : t("removeFromWishlist")}</span>
                  </span>
                  <span className="tagList">
                    <IoShareSocial className="product_share_icon" />
                  </span>
                  <RWebShare
                    data={{
                      text: productData.product_Description ?? "",
                      url: window.location.href,
                      title: productData.product_Heading,
                    }}
                  >
                    <button className="tagList border-0 bg-transparent">{t("product-page.share")}</button>
                  </RWebShare>
                </div>
              )}
              <div
                style={{
                  order: "2",
                }}
                className="order_Page_Right product_Page_Right product-child-2"
              >
                <div className="d-flex justify-content-md-between h-100 flex-column">
                  <div className="product_Section_1 pb-0 d-flex justify-content-start h-100 flex-column">
                    <div className="order_page_img_border">
                      <div className="product_Preview_Section">
                        <Carousel interval={5000} infiniteLoop showThumbs={false} showStatus={false}>
                          {productData.product_image_List.map((image, index) => (
                            <Carousel.Item key={index}>
                              <img className="d-block w-100 product_Carousel_Image" src={image} alt={`product-${index}`} onClick={() => openModal(image)} />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      </div>
                    </div>
                    {discountTillDate && (
                      <span
                        style={{
                          marginTop: "10px",
                          fontSize: "15px",
                          fontWeight: "700",
                          lineHeight: "19px",
                        }}
                      >
                        {t("dontMissProduct")}
                      </span>
                    )}
                    <h3
                      className="product_Name"
                      style={{
                        // fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "25px",
                        lineHeight: "25px",
                        color: "#000000",
                        margin: "10px 0",
                      }}
                    >
                      {productData.product_loaded === false ? <SkeletonElement type={"productTitle"} /> : productData.product_Heading ? productData.product_Heading : productData.product_name}
                    </h3>
                    <p className="product-desc">{productData.product_Description[0]}</p>
                    <span className="desc-readMore" onClick={() => productDescref.current && productDescref.current.scrollIntoView()}>
                      {t("readMore")}{" "}
                    </span>
                    <div className={`d-flex mt-1 align-items-center`} style={{ cursor: "pointer" }} onClick={() => document.querySelector(".reviewHead").scrollIntoView()}>
                      <div className="btn-best-seller">
                        {/* <button
                          className="btn btn-primary btn-res-style border-0"
                          style={{
                            fontWeight: "600",
                            fontSize: isMobile ? "11px" : "14px",
                            padding: isMobile && "7px 5px",
                          }}
                        >
                          {t("product-page.best-seller")}
                        </button> */}
                      </div>
                      <Rating value={Math.ceil(reviewDetails?.ratingAvg)} />

                      {/* <p
                        style={{
                          marginTop: "20px",
                          fontSize: !isMobile ? "10px" : "13px",
                          fontWeight: "700",
                          lineHeight: "19px",
                          display: isMobile ? "inline-block" : "",
                        }}
                      >
                        <span style={{ color: "#7F7F7F" }}>
                          {reviewDetails.totalReviews > 0 ? `${reviewDetails.totalReviews} Reviews` : `${reviewDetails.totalReviews} Review`} | {reviewDetails.totalSolds} Sold
                        </span>
                      </p> */}
                    </div>
                    {discountTillDate && productSecondData.activeDiscountType === OFFER_TYPES.DISCOUNT.key && (
                      <div className="d-flex mt-2">
                        <span className="limitedTimeDeal" style={{ backgroundColor: "orange", color: "white" }}>
                          {t("Limited_time_deal")}
                        </span>
                      </div>
                    )}
                    {!isMobile ? (
                      <>
                        {" "}
                        {productData.product_color ? (
                          <p
                            style={{
                              marginTop: "10px",
                              fontSize: "20px",
                              fontWeight: "600",
                              lineHeight: "19px",
                            }}
                          >
                            {t("Colour")}:
                            <span
                              className="text-uppercase"
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              {" "}
                              {alternateColorHover.isHover ? alternateColorHover.alternateColorText : productData.product_color}
                            </span>
                          </p>
                        ) : null}
                      </>
                    ) : (
                      productData.product_color && (
                        <p
                          style={{
                            marginTop: "20px",
                            fontSize: "20px",
                            fontWeight: "700",
                            lineHeight: "19px",
                          }}
                        >
                          {t("Colour")}:
                          <span
                            style={{
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            {alternateColorHover.isHover ? alternateColorHover.alternateColorText : productData.product_color}
                          </span>
                        </p>
                      )
                    )}
                    <div className="d-flex gap-3 flex-wrap">
                      {/* <div className=" d-flex flex-column text-decoration-none  Current_color_Img">
                        <img src={productData?.product_image_List[0]} alt={`current Product`} />
                         {productData.product_color} 
                      </div> */}
                      {/* {!alternateColorLoading ? ( */}
                      {/* {alternateColorean.length > 0 && */}
                      {alternateColorean.map((ele, index) => (
                        <Link
                          to={`/product/${ele?.slug}`}
                          className={`section_Wrapper d-flex flex-column text-decoration-none color-product-box `}
                          style={ele.colorCode === productData.product_color ? { border: "orange 1px solid" } : {}}
                          key={`Alternate_Color_${index}`}
                          onMouseEnter={() => setAlternateColorHover((prev) => ({ ...prev, isHover: true, alternateColorText: ele?.colorCode }))}
                          onMouseLeave={() => setAlternateColorHover((prev) => ({ ...prev, isHover: false, alternateColorText: "" }))}
                        >
                          <img src={ele.image[0]} alt={`Alternate_Color_Img_${ele?.colorCode}`} />
                        </Link>
                      ))}
                      {/* // ) : (
                      //   <div className="w-100">
                      //     <Skeleton variant="rectangular" width={400} height={110} />
                      //   </div>
                      // )} */}
                    </div>
                    {/* {!alternateColorLoading ? ( */}
                    {/* {alternateSpecean?.length > 0 && ( */}
                    <div className="specs-section-wrapper" style={{ marginTop: "5%" }}>
                      <div className="d-flex flex-row justify-content-start flex-wrap gap-3">
                        {alternateSpecean?.map((ele, index) => (
                          <Link
                            to={`/product/${ele?.slug}`}
                            className="text-decoration-none specs-product-box section_Wrapper productTag"
                            key={`Alternate_Specs_${index}`}
                            style={ele?.value === productData.value ? { border: "orange 1px solid " } : {}}
                          >
                            {ele?.value}
                          </Link>
                        ))}
                      </div>
                    </div>
                    {productData.product_L2 === "Smartphone" && (
                      <div style={{ marginTop: "8px" }}>
                        <a href={ExtendedWarrenty} target="_blank" rel="noopener noreferrer">
                          <img src={extendedWarrentyLogo} style={{ width: `${isMobile ? "50%" : "70%"}` }} alt="Extended warrenty" />
                        </a>
                      </div>
                    )}
                    {/* {onsitegoData.length > 0 && !isComingSoonProd && (
                      <div className="d-flex mt-2">
                        <div className="rounded p-3" style={{ fontSize: "12px", border: "orange 1px solid" }}>
                          <ul className="p-0">
                            {onsitegoData.map((item, index) => (
                              <li key={index} className="list-unstyled">
                                <div className="d-flex align-items-center justify-content-between option-container">
                                  <div className="d-flex align-items-center" style={{ gap: "15px" }}>
                                    {item.productType.includes("Extended Warranty") ? (
                                      <img src={warranty} height="35px" alt="OnsiteGo Protection" />
                                    ) : item.productType.includes("Spills and Drops / Damage Protection") ? (
                                      <img src={spill} height="30px" alt="OnsiteGo Protection" />
                                    ) : (
                                      <img src={screenprotection} height="30px" alt="OnsiteGo Protection" />
                                    )}

                                    <span style={{ marginRight: "5px", width: "250px" }}>
                                      <span>Add Onsitego {item.productType}</span> <b>@ {currencyFormat(item.unit_price)}</b>&nbsp;&nbsp;
                                      <span className="floater_Add_Cart" style={{ fontSize: "12px" }}>
                                        {item.productType.includes("Extended Warranty") ? (
                                          <a href={ExtendedWarrenty} target="_blank" rel="noopener noreferrer">
                                            T&C
                                          </a>
                                        ) : item.productType.includes("Spills and Drops / Damage Protection") ? (
                                          <a href={spillDrop} target="_blank" rel="noopener noreferrer">
                                            T&C
                                          </a>
                                        ) : (
                                          <a href={screenPlanProtection} target="_blank" rel="noopener noreferrer">
                                            T&C
                                          </a>
                                        )}
                                      </span>
                                    </span>
                                  </div>
                                  <div>
                                    <input
                                      className="cursor-pointer"
                                      type="checkbox"
                                      id={`onsitGoChecked${item.onsitego_sku}`}
                                      style={{ width: "15px", height: "15px" }}
                                      checked={selectedSkus.includes(item.onsitego_sku)}
                                      disabled={inCart}
                                      onChange={(e) => handleOnsiteGoChange(e, item)}
                                    />
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )} */}

                    {/* </div>
                    )} */}
                    {/* ) : (
                      <div className="w-100">
                        <Skeleton className="mt-2" variant="rectangular" width={200} height={40} />
                      </div>
                    )} */}
                    {discountTillDate && productSecondData.activeDiscountType === OFFER_TYPES.COMBO.key && comboProductData?._id && (
                      <div>
                        <h2 className="combo-offer-title">{t("comboOffer")}</h2>
                        <div className="d-flex">
                          <div className="combo-image-wrapper shadow rounded-3">
                            <img className="rounded-3" src={comboProductData?.images?.[0]} alt={comboProductData?.title} />
                            {/* <input
                                                            className="combo-checkbox"
                                                            type="checkbox"
                                                        /> */}
                          </div>
                          <div className="ps-3">
                            <p className="combo-product-name">{comboProductData?.title}</p>
                            <div className="d-flex align-items-center">
                              <h3 className="combo-free-text">₹{getComboProdPrice(comboProductData?.price?.mop, productData.product_Discount.combo)}</h3>
                              <h5 className="combo-product-price ps-3">
                                M.R.P.: <span className="text-decoration-line-through">₹{comboProductData?.price?.mrp}</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div
                                    style={{
                                        // marginTop: !isMobile ? "35%" : "10%",
                                        display: !isMobile ? "" : "none",
                                    }}
                                >
                                    <div
                                        className="section_Wrapper productTag w-100 justify-content-around"
                                        style={{
                                            background: "#F8F9FA",
                                            height: "66px",
                                            color: "#3581C1",
                                        }}
                                    >
                                        <div className="d-flex mx-3">
                                            <CompareIcon />
                                            <span>{t('COMPARE')}</span>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                marginRight: "10%",
                                            }}
                                        >
                                            <span
                                                className="d-flex flex-wrap"
                                                style={{
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {t('compareForBestDecision')}
                                            </span>
                                            <ArrowDropDownIcon />
                                        </div>
                                    </div>
                                </div> */}
                </div>
              </div>
              <div className="section_Wrapper border-0 product-child-3   d-flex flex-column">
                <div style={{ color: "white", backgroundColor: "transparent", height: "22px" }}>|</div>
                {discountTillDate ? (
                  <div className="d-flex justify-content-between">
                    <h2 className="offer-lasts">{t("offerLasts")}</h2>
                    <div className="d-flex justify-content-between align-items-center offer-time-wrapper w-100 px-2 rounded-2">
                      <div className="d-flex flex-column align-items-center">
                        <h6 className="offer-time-number m-0">{days}</h6>
                        <h6 className="offer-time-text">{t("DAYS")}</h6>
                      </div>
                      <div style={{ marginTop: "-14px" }}>:</div>
                      <div className="d-flex flex-column align-items-center">
                        <h6 className="offer-time-number m-0">{hours}</h6>
                        <h6 className="offer-time-text">{t("HOURS")}</h6>
                      </div>
                      <div style={{ marginTop: "-14px" }}>:</div>
                      <div className="d-flex flex-column align-items-center">
                        <h6 className="offer-time-number m-0">{minutes}</h6>
                        <h6 className="offer-time-text">{t("MINUTES")}</h6>
                      </div>
                      <div style={{ marginTop: "-14px" }}>:</div>
                      <div className="d-flex flex-column align-items-center">
                        <h6 className="offer-time-number m-0">{seconds}</h6>
                        <h6 className="offer-time-text">{t("SECONDS")}</h6>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div
                  style={{
                    order: "3",
                    // marginTop: !isMobile ? "16px" : "10px",
                    border: "2px solid #CACCD1",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <div style={{ padding: "20px 20px 5px 20px" }}>
                    {productData.product_Classification === PRODUCT_CLASSIFICATION.COMING_SOON ? (
                      <></>
                    ) : (
                      <>
                        {productData.product_Classification === PRODUCT_CLASSIFICATION.PRE_BOOKED ? (
                          <></>
                        ) : (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                // gap: "",
                                flexWrap: "wrap",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <span className="netPrice">{currencyFormat(getFinalDisplayPrice(productData))}</span>
                                {discountPercent > 0 && (
                                  <span className="discountOffer" style={{ marginLeft: "10px" }}>
                                    {discountPercent}% off
                                  </span>
                                )}
                              </div>

                              {/* <div> */}

                              {productData.product_price.mrp > getFinalDisplayPrice(productData) && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "5px",
                                    alignItems: "center",
                                  }}
                                  className="realPrice"
                                >
                                  <span>M.R.P.: </span>
                                  <span style={{ textDecoration: "line-through", alignItems: "center" }}>
                                    <span className="product_Original_Price">{productMRP}</span>
                                  </span>
                                </div>
                              )}
                            </div>
                            <span className="Condition d-block mt-1">{t("InclusiveOfAllTaxes")}</span>
                          </>
                        )}
                      </>
                    )}
                    {productData.product_Classification === PRODUCT_CLASSIFICATION.PRE_BOOKED && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          // gap: "",
                          flexWrap: "wrap",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        {productData.preBookAmount && (
                          <div className="d-flex align-items-center">
                            <span className="netPrice">{currencyFormat(productData.preBookAmount)}</span>
                          </div>
                        )}

                        <span className="Condition d-block mt-1">{t("InclusiveOfAllTaxes")}</span>
                      </div>
                    )}
                    {productData.product_Classification !== PRODUCT_CLASSIFICATION.COMING_SOON &&
                      (productData.product_Classification !== PRODUCT_CLASSIFICATION.PRE_BOOKED ? (
                        <>
                          {stockLoading ? (
                            <span className="Condition" style={{ color: "white" }}>
                              {t("InStock")}
                            </span>
                          ) : (
                            <>
                              {+federatedStock > 1 ? (
                                <span className="Condition" style={{ color: "green" }}>
                                  {t("InStock")}
                                </span>
                              ) : (
                                <span className="Condition" style={{ color: "red" }}>
                                  {t("OutStock")}
                                </span>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      ))}

                    <div className="couoponContainer">
                      <div className="d-flex removeScroller" style={{ flexWrap: "nowrap", gap: "5px", overflowX: "auto", overflowY: "hidden" }}>
                        {walletOffers.map((item, index) => (
                          <div
                            key={index}
                            className={`coupon-card ${item.appliedStatus ? "applied" : ""}`}
                            style={{ width: 155 }} // set a fixed width for each card
                          >
                            <div className="d-flex flex-column  justify-content-between" style={{ height: "100%" }}>
                              <div>
                                Save <span className="coupon-value"> {currencyFormat(item.value)} </span>
                              </div>
                              <div className="d-flex  justify-content-between align-items-center">
                                <span className="cursor-pointer" style={{ fontSize: "9px", color: "#ffa500" }} onClick={() => setShow(true)}>
                                  T&amp;C
                                </span>
                                <button
                                  className=" badge badge-pill badge-light"
                                  style={{ border: `1px solid ${item.appliedStatus ? "#388e3c" : "#ffa500"} `, color: "black", background: "white" }}
                                  onClick={() => handleApplyCoupon(index)}
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {!userZip.value && (
                      <div className="d-flex justify-content-between" style={{ gap: "10px" }}>
                        <input type="number" id="userzip" placeholder="Pincode" style={{ paddingLeft: "5px", width: "80%", border: "1px solid black" }} />
                        <button type="button" className="btn btn-sm " style={{ backgroundColor: "#1b325e", color: "white", padding: "5px" }} onClick={setUserPincode}>
                          {t("submit")}
                        </button>
                      </div>
                    )}
                  </div>
                  {/* <div
                                    style={{
                                        background: "#FFF0BF",
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "20px",
                                    }}
                                >
                                    <img src={EMIIcon} alt="" />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <span className="Condition">
                                            {t('EasyPaymentPlans')}{" "}
                                        </span>
                                        <span
                                            className="Condition"
                                            style={{ fontWeight: "500" }}
                                        >
                                            {t('EMIStarts')}
                                        </span>
                                    </div>
                                    <span>
                                        {t('Details')}
                                        <ArrowDropDownIcon />
                                    </span>
                                </div> */}
                  {discountTillDate && productSecondData.activeDiscountType === OFFER_TYPES.DISCOUNT.key ? (
                    <>
                      <div style={{ padding: "10px 20px" }} className="d-flex align-items-start">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "5px",
                          }}
                        >
                          <img src={OfferIcon} alt="" />
                          <span
                            style={{
                              fontWeight: "700",
                              fontSize: "20px",
                              lineHeight: "25px",
                              color: "#000000",
                            }}
                          >
                            {t("product-page.offers")}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          alignItems: "center",
                          padding: "5px 10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ElasticCarousel divsToShow={1} itemsToScroll={1} itemsToShow={3} style={{ marginTop: "20px" }} transitionMs={1000} renderArrow={customArrow}>
                          <div
                            className="section_Wrapper"
                            style={{
                              background: "#F8F9FA",
                              padding: "10px",
                              display: "flex",
                              flexDirection: "column",
                              marginRight: "5px",
                            }}
                          >
                            <span className="offername small">{t("FlatDiscount")}</span>
                            <span
                              className="offername small pt-2"
                              style={{
                                fontWeight: "400",
                              }}
                            >
                              {t("product-page.flat-discount")} {productSecondData.discount?.flatDiscount?.value} {productSecondData.discount?.flatDiscount?.discountType === "percentage" ? "%" : "₹"}
                            </span>
                            <span
                              className="offername small"
                              style={{
                                color: "#3581C1",
                                marginTop: "10px",
                              }}
                            >
                              {t("product-page.see-more")} &nbsp; &nbsp; <img src={arrowRight} alt="arrowRight" />
                            </span>
                          </div>

                          {/* {BankArray.map((item) => (
                                <div
                                    className="section_Wrapper"
                                    key={item}
                                    style={{
                                        background: "#F8F9FA",
                                        padding: "10px",
                                        display: "flex",
                                        flexDirection: "column",
                                        // width: "32%",
                                        marginRight: "5px",
                                    }}
                                >
                                    <span className="offername small">
                                        Bank Offer
                                    </span>
                                    <span className="small">
                                        <img
                                            src={
                                                item === 1
                                                    ? HDFC
                                                    : item === 2
                                                    ? ICICI
                                                    : SBI
                                            }
                                            alt="HDFC_BANK"
                                            className="bank_image"
                                        />
                                    </span>
                                    <span
                                        className="offername small"
                                        style={{ fontWeight: "400" }}
                                    >
                                        ₹499 Discount on HDFC Bank Credit Card
                                    </span>
                                    <span
                                        className="offername small"
                                        style={{
                                            color: "#3581C1",
                                            marginTop: "10px",
                                        }}
                                    >
                                        {t('product-page.see-more')} &nbsp; &nbsp;{" "}
                                        <img
                                            src={arrowRight}
                                            alt="arrowRight"
                                        />
                                    </span>
                                </div>
                            ))} */}
                        </ElasticCarousel>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* <div className="product_Offer_Counter">
                        {productData.product_loaded ? (
                            productData.product_Classification ===
                            PRODUCT_CLASSIFICATION.COMING_SOON ? (
                                preOrder ? (
                                    <p>{t("product-page.claimed")}</p>
                                ) : (
                                    ""
                                )
                            ) : discountTillDate ? (
                                <>
                                    {productSecondData.activeDiscountType ===
                                        OFFER_TYPES.DISCOUNT.key && (
                                        <p>
                                            {t("product-page.flat-discount")}{" "}
                                            {
                                                productSecondData.discount
                                                    ?.flatDiscount?.value
                                            }{" "}
                                            {productSecondData.discount
                                                ?.flatDiscount?.discountType ===
                                            "percentage"
                                                ? "%"
                                                : "₹"}
                                        </p>
                                    )}
                                    <p>
                                        {t("product-page.deal-end")} {days}d{" "}
                                        {hours}h {minutes}m {seconds}s
                                    </p>
                                </>
                            ) : (
                                <></>
                            )
                        ) : (
                            <SkeletonElement type={"productTitle"} />
                        )}
                    </div> */}

                  <div
                    style={{
                      width: "100%",
                      padding: "10px 20px",
                    }}
                  >
                    <Row>
                      <Col xs="4" sm="4" className="d-flex flex-column align-items-center justify-content-evenly col-4">
                        {" "}
                        <img src={delivery_truck} style={{ objectFit: "contain", height: "35px" }} alt="delivery_truck" />
                        <span className="services-title mt-1">{t("FreeShipping")}</span>
                      </Col>
                      <Col xs="4" sm="4" className="d-flex flex-column align-items-center justify-content-evenly col-4">
                        <img src={credit_card} style={{ objectFit: "contain", height: "35px" }} alt="credit_card" />
                        <span className="services-title mt-1">{t("EasyEMI")}</span>
                      </Col>
                      <Col xs="4" sm="4" className="d-flex flex-column align-items-center justify-content-evenly col-4">
                        <img src={exchange} style={{ objectFit: "contain", height: "35px" }} alt="exchange" />
                        <span className="services-title mt-1">{t("ReturnsNExchange")} </span>
                      </Col>
                      <Col xs="4" sm="4" className="d-flex flex-column align-items-center justify-content-evenly">
                        <img src={cancellation_policy_icon} style={{ objectFit: "contain", height: "35px" }} alt="cancellation_policy_icon" />
                        <span className="services-title mt-1">{t("CancellationPolicy")}</span>
                      </Col>
                      <Col xs="4" sm="4" className="d-flex flex-column align-items-center justify-content-evenly">
                        <img src={guraentee} style={{ objectFit: "contain", height: "35px" }} alt="ribbon" />
                        <span className="services-title mt-1">
                          {productData.warranty} {t("warranty")}
                        </span>
                      </Col>
                      <Col xs="4" sm="4" className="d-flex flex-column align-items-center">
                        <img src={days_replacement} style={{ objectFit: "contain" }} alt="" />
                        <span className="services-title mt-2">{t("7DaysReplacement")}</span>
                      </Col>
                    </Row>
                  </div>

                  {productData.product_Classification !== PRODUCT_CLASSIFICATION.COMING_SOON && productData.product_Classification !== PRODUCT_CLASSIFICATION.PRE_BOOKED && (
                    <>
                      {productData.product_L2 !== "Warranties" && (
                        <div className="d-flex justify-content-center align-items-center gap-3">
                          <RemoveIcon
                            color="primary"
                            sx={{
                              bgcolor: "#F8F9FA",
                              fontSize: "30px",
                              borderRadius: "5px",
                              padding: "5px",
                            }}
                            onClick={handleQuantityDec}
                          />

                          <span className="pagination-text border border-1 py-1  px-2 rounded">{productSecondData.quantity}</span>
                          <AddIcon
                            color="primary"
                            sx={{
                              bgcolor: "#F8F9FA",
                              fontSize: "30px",
                              borderRadius: "5px",
                              padding: "5px",
                            }}
                            onClick={handleQuantityInc}
                          />
                        </div>
                      )}
                    </>
                  )}

                  {productData.product_L2 === "Warranties" ? (
                    <p className="floater_Add_Cart" style={{ textDecoration: "none", color: "#081a4f" }}>
                      {productData.product_name.includes("Extended Warranty") ? (
                        <>
                          <a href={ExtendedWarrenty} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#081a4f" }}>
                            Get Terms And Condition
                          </a>
                        </>
                      ) : (
                        <>
                          {productData.product_name.includes("Spills and Drops / Damage Protection") ? (
                            <>
                              <a href={spillDrop} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#081a4f" }}>
                                Get Terms And Condition
                              </a>
                            </>
                          ) : (
                            <>
                              <a href={screenPlanProtection} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#081a4f" }}>
                                Get Terms And Condition
                              </a>
                            </>
                          )}
                        </>
                      )}
                    </p>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "10px 20px",
                      }}
                    >
                      {isComingSoonProd ? (
                        <div className="floating_Footer_Center">
                          <div className="submit_Button_2">
                            <button type="submit" className="submit-button" onClick={notifyUserInit}>
                              <p>{thisProdSubs ? t("product-page.unsubscribe", "Unsubscribe") : t("product-page.notify")}</p>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          {isPreBookedProd ? (
                            <div className="floating_Footer_Center">
                              <div className="submit_Button_2">
                                <button type="button" className="submit-button" onClick={preeBookOrder}>
                                  <p>{thisProdPreBooked ? "Pre Booked" : "Pre Book Now "}</p>
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-between w-100" style={{ padding: "10px 20px" }}>
                              <div className="submit_Button_3" style={{ width: "47%" }}>
                                <button
                                  disabled={inCart || isComingSoonProd || !federatedStock > 0}
                                  type="submit"
                                  className="submit-button"
                                  style={{
                                    background: "#1B325E",
                                    maxWidth: "130px",
                                    height: "35px",
                                  }}
                                  onClick={() => handleAddToCart(productData.product_Id)}
                                >
                                  <p
                                    style={{
                                      color: "white",
                                    }}
                                  >
                                    {inCart ? t("Addedincart") : t("product-page.add-to-cart")}
                                  </p>
                                </button>
                              </div>
                              <button
                                disabled={!isProdServiceable || isComingSoonProd}
                                type="submit"
                                className="submit-button"
                                style={{
                                  background: "#FFCC0A",
                                  color: "white",
                                  maxWidth: "130px",
                                  height: "35px",
                                  width: "47%",
                                }}
                                onClick={handleOrderInit}
                              >
                                <p>{t("product-page.buy-now")}</p>
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {/*                                 
                                {isComingSoonProd && (
                                    <div className="floating_Footer_Center">
                                        <div className="submit_Button_2">
                                            <button
                                                type="submit"
                                                className="submit-button"
                                                onClick={notifyUserInit}
                                            >
                                                <p>{thisProdSubs ? t("product-page.unsubscribe", "Unsubscribe") : t("product-page.notify")}</p>
                                            </button>
                                        </div>
                                    </div>
                                )} */}

                  {/* {isComingSoonProd && (
                                    <div
                                        className="submit_Button_2"
                                        onClick={() =>
                                            handleAddToWishlist(
                                                productData.product_Id
                                            )
                                        }
                                    >
                                        <button
                                            type="submit"
                                            className="submit-button"
                                        >
                                            <p>
                                                {t("product-page.add-to-wishlist")}
                                            </p>
                                        </button>
                                    </div>
                                )} */}
                </div>
              </div>
            </div>

            {/* Product Key features */}
            {/* {productInfo.length > 0 && (
                        <div style={{ margin: "15px 0" }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <h5 className="key_feature_heading">
                                    {t("product-page.key-features")}:
                                </h5>
                                <div
                                    style={{
                                        background: "#EEEEEE",
                                        height: "4px",
                                        width: "65%",
                                    }}
                                    className="product_Section_Heading_empty_div"
                                ></div>
                            </div>

                            <ProductKeyFeatureTable
                                product_Information={productInfo}
                            />
                        </div>
                    )} */}

            {/* Product Information Table */}
            {productData.product_L2 !== "Warranties" && (
              <>
                {productInfo.length > 0 && (
                  <div style={{ margin: isMobile ? "30px 0" : 0, padding: isMobile ? "0px 10px" : 0 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h5 className="key_feature_heading" style={{ marginRight: "10px" }}>
                        {t("product-page.prod-info")}
                      </h5>
                      <div
                        style={{
                          background: "#EEEEEE",
                          height: "4px",
                          flex: "1 auto",
                        }}
                        className="product_Section_Heading_empty_div"
                      ></div>
                    </div>

                    <ProductInfoTable product_Information={productInfo} />
                  </div>
                )}
              </>
            )}

            {/* Banner */}
            {productData.product_Gallery_Image.length > 0 && (
              <div style={{ paddingLeft: isMobile ? "10px" : "" }}>
                <img
                  style={{
                    margin: "3px 0",
                    borderRadius: "10px",
                    marginBottom: "1rem",
                  }}
                  src={productData.product_Gallery_Image[0]}
                  className={`product_Gallery_Image`}
                  alt="first_gallery_image"
                />
              </div>
            )}

            {/* Product Description */}
            {productData.product_loaded ? (
              productData.product_Description?.length > 0 && (
                <>
                  <List
                    sx={{
                      // margin: "15px 0",
                      listStyleType: "disc",
                      "& .MuiListItem-root": {
                        display: "list-item",
                      },
                      padding: isMobile ? "0px 10px" : 0,
                    }}
                    style={{ lineHeight: "19px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h5 className="key_feature_heading" ref={productDescref} style={{ scrollMarginTop: "4rem", marginRight: "10px" }}>
                        {t("ProductDescription")}
                      </h5>
                      <div
                        style={{
                          background: "#EEEEEE",
                          height: "4px",
                          flex: "1 auto",
                        }}
                        className="product_Section_Heading_empty_div"
                      ></div>
                    </div>

                    {showAll
                      ? productData.product_Description.map((e, index) => (
                          <ListItem sx={{ paddingBottom: 0, marginLeft: "1rem" }} key={index}>
                            {e}
                          </ListItem>
                        ))
                      : productData.product_Description.slice(0, 2).map((e, index) => (
                          <ListItem sx={{ paddingBottom: 0, marginLeft: "1rem" }} key={index}>
                            {e}
                          </ListItem>
                        ))}
                  </List>
                  {!showAll ? (
                    <>
                      {productData.product_Description.length > 2 && (
                        <span className="text-primary cursor-pointer" onClick={toggleShowAll}>
                          See More...
                        </span>
                      )}
                    </>
                  ) : (
                    <span className="text-primary cursor-pointer" onClick={toggleShowAll}>
                      See Less
                    </span>
                  )}
                </>
              )
            ) : (
              <>
                <Skeleton animation="wave" />
                <Skeleton variant="rectangular" height={60} />
              </>
            )}

            {/* Image Gallery */}
            <div style={{ marginBottom: "15px", paddingLeft: isMobile ? "8px" : "", paddingRight: isMobile ? "8px" : "" }}>
              {productData.product_Gallery_Image.length > 0 && (
                <img
                  style={{
                    margin: "3px 0",
                    marginBottom: "17px",
                    borderRadius: "10px",
                  }}
                  src={productData.product_Gallery_Image[1]}
                  className={`product_Gallery_Image`}
                  alt="second_product_banner"
                />
              )}
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                }}
              >
                {/* .slice(1) */}
                {productData.product_loaded
                  ? productData.product_Gallery_Image.length > 1 &&
                    productData.product_Gallery_Image.slice(2).map((image, index) => (
                      <img
                        style={{
                          width: "49.5%",
                          objectFit: "contain",
                          borderRadius: "10px",
                        }}
                        src={image}
                        key={`product_lower_smaller_images_${index}`}
                        className={`product_Gallery_Image`}
                        alt={`product_lower_smaller_images_${index}`}
                      />
                    ))
                  : [1].map((n) => <SkeletonElement type={"productBanner"} key={n} />)}
              </div>
            </div>

            {/* Floating Footer */}
            {!width >= 768 && (
              <div className="floating_Footer">
                <div className="floating_Footer_Wrapper product_Page_Floating_Wrapper">
                  {productData.product_Classification === PRODUCT_CLASSIFICATION.COMING_SOON ? (
                    <div className="floating_Footer_Center">
                      <div className="submit_Button_2">
                        <button type="button" className="submit-button">
                          <p>{t("product-page.notify")}</p>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div style={{ textDecoration: "none", color: "#081a4f" }}>
                      {productData.product_L2 === "Warranties" && !isComingSoonProd ? (
                        <p className="floater_Add_Cart" style={{ textDecoration: "none", color: "#081a4f" }}>
                          {productData.product_name.includes("Extended Warranty") ? (
                            <>
                              <a href={ExtendedWarrenty} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#081a4f" }}>
                                Get Terms And Condition
                              </a>
                            </>
                          ) : (
                            <>
                              {productData.product_name.includes("Spills and Drops / Damage Protection") ? (
                                <>
                                  <a href={spillDrop} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#081a4f" }}>
                                    Get Terms And Condition
                                  </a>
                                </>
                              ) : (
                                <>
                                  <a href={screenPlanProtection} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#081a4f" }}>
                                    Get Terms And Condition
                                  </a>
                                </>
                              )}
                            </>
                          )}
                        </p>
                      ) : (
                        <>
                          <div className="floating_Footer_Left">
                            <button type="submit" className="submit-button" disabled={inCart || isComingSoonProd}>
                              {/* {userContext ? ( */}
                              <p className="floater_Add_Cart" onClick={() => handleAddToCart(productData.product_Id)}>
                                {inCart ? t("Addedincart") : t("product-page.add-to-cart")}
                              </p>
                              {/* ) : (
                            <Link to={"/login"} className="floater_Add_Cart">
                              {t("product-page.add-to-cart")}
                            </Link>
                          )} */}
                            </button>
                          </div>
                          <div className="floating_Footer_Right">
                            <button type="submit" className="submit-button" onClick={handleOrderInit} disabled={!isProdServiceable || isComingSoonProd}>
                              <p>{t("product-page.buy-now")}</p>
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            <Review productId={productData.product_Id} />
            {/* {this Component is Originally Defined in MyCart Component for css look in MyCart.css} */}

            <div className="suggested-product-container" style={{ paddingLeft: isMobile ? "8px" : "", paddingRight: isMobile ? "8px" : "" }}>
              <div className="suggested-heading d-flex justify-content-start align-items-center">
                <div
                  className="sub-head-cart1"
                  style={
                    {
                      // marginRight: "2rem",
                      // alignItems: "center",
                    }
                  }
                >
                  <span className="text4_xl" style={{ fontSize: "17px" }}>
                    {t("FrequentlyBroughtTogether")}
                  </span>
                </div>
                <div className="sub-head-cart2" id="head-bar-sugges" style={{ flex: "1 auto" }}></div>
              </div>
              <div className="card-display-row">
                {emptyOrdersProductArray.map((e, i) => (
                  <ProductCard
                    key={`${i}-${e?._id}`}
                    id={e?._id ?? ""}
                    productImg={e?.images?.[0] ?? null}
                    productName={e?.title}
                    productDescription={e?.description?.[0]?.split(" ")?.slice(0, 18)?.join(" ") ?? ""}
                    productRating={e?.rating ?? 0}
                    productPriceObj={e?.price}
                    productDiscountObj={e?.discount}
                    productSlug={e?.slug}
                    wishlist={userWishlist.wishlist_items?.some((obj) => obj?._id?.toString() === e?._id?.toString())}
                    cart={cartArray?.no_of_carts > 0 && cartArray?.cart.some((obj) => obj?._id?.toString() === e?._id?.toString())}
                    classification={e?.classification}
                    preBookData={e?.preBook}
                  />
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="store_Finder_Loader mt-4">
            <div className="loader-div rounded d-flex flex-column">
              {/* <CircularProgress color="inherit" /> */}
              <img src={loadingImg} alt="loading_tab" className="loading-image rounded" />
              Loading...
            </div>
          </div>
        )}
      </div>

      {show && <TermsAndCondtionModal open={show} onClose={() => handleCloseModal()} termData={termData} />}
    </>
  );
};

export default ProductPage;
