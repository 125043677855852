import React from "react";
import { useMediaQuery } from "@mui/material";
import useScreenSize from "../../utils/ScreenSizeCalculation";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-bootstrap";

export default function DealsSection({ heading, products, section }) {
  const isMobile = useMediaQuery("(max-width: 570px)");
  const screenSize = useScreenSize();
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-evenly",
        marginBottom: "10px",
        padding: "0 10px",
        gap: 5,
      }}
    >
      {section === "static_deals_mobile" ? (
        <>
          <Carousel interval={5000} className="w-100">
            {products.map((product, index) => (
              <Carousel.Item key={index}>
                <div className="all-categories-banner">
                  <img
                    src={product.image}
                    alt={product.title}
                    loading="lazy"
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      maxHeight: "450px",
                    }}
                    onClick={() => navigate(product.targetUrl ?? "/")}
                  />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </>
      ) : (
        <>
          {products.map((product, index) => (
            <img
              className="cursor-pointer"
              style={{
                width: !isMobile ? "" : "50%",
                height: !isMobile ? "330px" : "150px",
                objectFit: "contain",
                borderRadius: !isMobile ? "10px" : index === 0 ? "0px 10px 10px 0px" : "10px 0px 0px 10px",
              }}
              title={product.title}
              src={product.image}
              alt={`${product.title}-${index}`}
              onClick={() => navigate(product.targetUrl ?? "/")}
              key={`Deals_Section_${index}`}
            />
          ))}
        </>
      )}
    </div>
  );
}
