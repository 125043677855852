//Dependencies
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import SegmentIcon from "@mui/icons-material/Segment";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { CircularProgress, Drawer, Paper } from "@mui/material";
//CSS
import "./ProductCategory.css";

//Components
import { getSearchValuesForDynamicFilters, getSearchedProduct, getValuesForDynamicFilters } from "../../api/Product";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useProductCtx from "../../hooks/useProductCtx";
import ProductCard from "../../components/Cards/ProductCard";
import Pagination from "@mui/material/Pagination";
//Utils
import { PRODUCT_CLASSIFICATION } from "../../utils/Constants";
import { useTranslation } from "react-i18next";

import useScreenSize from "../../utils/ScreenSizeCalculation";

//new images
import Laptop1 from "../../assets/vector/All_Categories/pngaaa_1.svg";
import Laptop2 from "../../assets/vector/All_Categories/pngaaa_2.svg";
import Laptop3 from "../../assets/vector/All_Categories/pngaaa_3.svg";
import Laptop4 from "../../assets/vector/All_Categories/pngegg_1.svg";
import Laptop5 from "../../assets/vector/All_Categories/lovoLap.svg";
import Laptop6 from "../../assets/vector/All_Categories/kindpng_6093321_1.svg";
import intel3 from "../../assets/vector/All_Categories/Intel_Core_i3_Logo_2020.svg";
import intel5 from "../../assets/vector/All_Categories/Intel_Core_i5_Logo_2020.svg";
import intel7 from "../../assets/vector/All_Categories/Intel_Core_i7_Logo_2020.svg";
import intel9 from "../../assets/vector/All_Categories/Intel_Core_i9_Logo_2020.svg";
import pro3 from "../../assets/vector/All_Categories/pro3.svg";
import pro5 from "../../assets/vector/All_Categories/pro5.svg";
import pro7 from "../../assets/vector/All_Categories/amd_ryzen_7_logo.svg";
import pro9 from "../../assets/vector/All_Categories/amd_ryzen_9_logo.svg";

import { useMediaQuery } from "@mui/material";
import DrawerSection from "./DrawerSection";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import FilterListData from "../../components/FilterListData";
import useUserCtx from "../../hooks/useUserCtx";
import loadingImg from "../../assets/png/loading.jpg";
import { Carousel } from "react-bootstrap";
import SkeletonElement from "../../components/Skeletons/SkeletonElement";
import useSiteCtx from "../../hooks/useSiteCtx";
import SEO from "../../components/SEO/SEO";
// Component definition

const ProductCategory = () => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  // translation
  const { t } = useTranslation();
  const { seoData } = useSiteCtx();
  const { userWishlist, cartArray, setFilterSelected, filterSelected } = useUserCtx();

  const [minMaxPrice, setMinMaxPrice] = useState({ highest: 100, lowest: 0 });
  const [priceRange, setPriceRange] = useState([minMaxPrice.lowest, minMaxPrice.highest]);

  const [step, setStep] = useState(1);

  const scrollToProductCard = () => {
    document.querySelector(".main_Content_Show ").scrollIntoView();
  };

  const handleChange = (event, newValue) => {
    setPriceRange(() => newValue);
  };
  const { homePageSections } = useSiteCtx();

  const [carouselData, setCarouselData] = useState(() => homePageSections.products);

  function handlePriceRangeSubmit() {
    setFilterSelected((prev) =>
      prev.some((e) => e.type === "price.mop")
        ? [...prev.filter((e) => e.type !== "price.mop"), { type: "price.mop", searchQ: priceRange.join("-") }]
        : [...prev, { type: "price.mop", searchQ: priceRange.join("-") }]
    );
    isMobile && handleClose();
    scrollToProductCard();
    setCurrentPage(1);
  }

  function onPriceClick(range) {
    if (range[0] === priceRange[0] && range[1] === priceRange[1]) {
      setPriceRange([minMaxPrice.lowest, minMaxPrice.highest]);
      setFilterSelected((prev) => [...prev.filter((e) => e.type !== "price.mop"), { type: "price.mop", searchQ: `${minMaxPrice.lowest}-${minMaxPrice.highest}` }]);
    } else {
      setPriceRange(range);
      setFilterSelected((prev) =>
        prev.some((e) => e.type === "price.mop")
          ? [...prev.filter((e) => e.type !== "price.mop"), { type: "price.mop", searchQ: range.join("-") }]
          : [...prev, { type: "price.mop", searchQ: range.join("-") }]
      );
    }
    isMobile && handleClose();
    scrollToProductCard();
  }

  function handlePriceReset() {
    setPriceRange([minMaxPrice.lowest, minMaxPrice.highest]);
    isMobile && handleClose();
    setFilterSelected([]);
    scrollToProductCard();
    setCurrentPage(1);
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalProducts, setTotalProducts] = useState(1);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [sorting, setSorting] = useState("");
  const [filterListData, setFilterListData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(1);
  const [lowerBanners, setLowerBanners] = useState([]);

  const { width } = useWindowDimensions();

  const matches = width >= 768;
  const nav = useNavigate();

  const { searchedProduct, setSearchedProduct, currentPage, setCurrentPage } = useProductCtx();
  const productsPerPage = 12;

  const location = useLocation();

  const handleOpen = (event) => {
    if (isMobile) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAll2 = (event, resp) => {
    setCurrentPage(1);
    setFilterSelected((filterObj) => {
      if (filterObj.some((filter) => filter.type === resp.type && filter.id === resp.id)) {
        return [...filterObj.filter((filter) => filter.id !== resp.id)];
      }
      return [...filterObj, resp];
    });
    scrollToProductCard();
  };

  const handleSingleFilterSelection = (resp) => {
    setCurrentPage(1);
    setFilterSelected((filterObj) => {
      if (filterObj.some((filter) => filter.type === resp.type)) {
        if (filterObj.some((filter) => filter.id !== resp.id)) return [...filterObj.filter((filter) => filter.type !== resp.type), resp];
        else return [...filterObj.filter((filter) => filter.type !== resp.type)];
      }
      return [...filterObj, resp];
    });
  };

  useEffect(() => {
    // setFilterSelected([]);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    if (Object.keys(FilterListData).includes(location.pathname.split("=")[1])) {
      getValuesForDynamicFilters(location.pathname.split("=")[1]).then((res) => {
        if (res.status === "success") generateDynamicFilterArray(res.obj);
      });
    } else if (location.search !== "") {
      getSearchValuesForDynamicFilters(location.search.split("=")[1]).then((res) => {
        if (res.status === "success") generateDynamicFilterArray(res.obj);
      });
    } else {
      setFilterListData(FilterListData["all"]);
    }
  }, [FilterListData, location]);

  useEffect(() => {
    setLoading(true);
    const params = new URLSearchParams(location.search);
    if (params.get("search") === "") return;
    if (location.pathname !== "/Category=all" && location.pathname !== "/search") {
      let urlArray = location.pathname.slice(1).split("=");
      params.append("hierarchyL2", urlArray[1]);
    }
    if (sorting === "descending") {
      params.append("sort", "descending");
    }
    if (sorting === "ascending") {
      params.append("sort", "ascending");
    }
    params.append("limit", productsPerPage);
    params.append("page", currentPage);
    filterSelected?.length > 0 &&
      filterSelected.forEach((value) => {
        if (value.type === "price.mop") {
          params.append(`${value.type}`, value.searchQ);
        }
        if (value.type === "brand") {
          params.append("brand", value.data);
        }
        if (value.type === "Category") {
          params.append("hierarchyL2", value.data);
        }
        if (value.type === "Offer") {
          if (value.data === "Any") return;
          params.append("discount.flatDiscount.value[gte]", value?.Qdata);
        }
        if (value?.dynamic) {
          params.append("dynamic", true);
          params.append(`${value.type}`, value.searchQ.toString().trim());
        }
      });
    params.append("isLive", true);
    getSearchedProduct(params).then((res) => {
      const products = res.products.filter((prod) => prod.classification !== PRODUCT_CLASSIFICATION.TEMP_HIDDEN);
      setSearchedProduct({
        loaded: true,
        products,
        no_of_products: res.total_products,
      });
      setTotalProducts(res.total_products);
      if (filterSelected?.length > 0) {
        const targetElement = document.querySelector(".mainContainerWrapper");

        // Scroll to the element if found
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      }
      setLoading(false);
    });
  }, [filterSelected, setSearchedProduct, currentPage, location, sorting]);

  const generateDynamicFilterArray = useCallback((objValues) => {
    const values = {
      ram: "GB",
      internal_storage: "GB",
      display_size: "Inches",
      screen_size: "Inches",
      warranty: "Year",
      total_playback_time: "Hours",
      refresh_rate: "Hz",
    };
    function checkIfAlreadyPresent(str, value) {
      return str?.toString()?.replace(/[0-9]/, "ig")?.trim()?.toLowerCase() === value?.toLowerCase();
    }
    setFilterListData(() => {
      const arr = FilterListData["all"];
      if (!objValues) return;
      const arr2 = Object.entries(objValues).map((entries) => {
        if (entries[0].toString() === "brand")
          return {
            filter_heading: entries[0].toString(),
            filter_data: entries[1].map((e, index) => ({
              type: `${entries[0]}`,
              data: e,
              searchQ: e,
              id: `${entries[0]}-${e}`,
            })),
          };
        else if (entries[0].toString() === "price")
          return {
            filter_heading: entries[0].toString(),
            filter_data: entries[1].map((e, index) => {
              if (index !== entries[1].length - 1)
                return {
                  type: "price.mop",
                  data: e.data,
                  searchQ: e.searchQ,
                  id: `${entries[0]}-${e.data}`,
                };
              else return null;
            }),
          };
        // Dynamic Filters
        else {
          // if (entries[1][0] === "__") {
          //   entries[1].shift(); // Remove the first element from entries[1]
          // }
          // if (entries[1][0] === "-") {
          //   entries[1] = entries[1].slice(1); // Remove the first element from entries[1]
          // }
          return {
            filter_heading: entries[0].toString().split("_").join(" "),
            filter_data: entries[1].map((e, index) => ({
              type: `productInfo.${entries[0]}`,
              data: values[entries[0]?.toString()] && checkIfAlreadyPresent(e, values[entries[0]?.toString()]) ? `${e} ${values[entries[0].toString()]}` : e,
              searchQ: e,
              id: `${entries[0]}_${index}`,
              dynamic: true,
            })),
          };
        }
      });
      const priceIndex = arr2.findIndex((item) => item.filter_heading === "price");

      // Move the element with filter_heading === "price" to the end
      if (priceIndex !== -1) {
        const priceElement = arr2.splice(priceIndex, 1)[0];
        arr2.push(priceElement);
      }

      return arr.concat(...arr2);
    });
    const { lowest, highest } = objValues.price[objValues.price.length - 1] ?? { lowest: 0, highest: 0 };
    setMinMaxPrice((prev) => ({ ...prev, lowest, highest }));
    setStep(() => Math.ceil((highest - lowest) / 100));
    setPriceRange((prev) => [lowest, highest]);
  }, []);

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    scrollToProductCard();
  };

  // POPUP
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClosePOP = (event, sortValue) => {
    // if (sortValue) {
    setSorting(sortValue);
    setCurrentPage(1);
    // }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const headingName = useMemo(() => {
    if (location.pathname?.split("=")[1]) return `${location.pathname?.split("=")[1]} Category`.toString().replaceAll("%20", " ");
    else return "Search Result";
  }, [location]);

  //Funtions For Laptop Category
  // Function to get filter data by heading
  function getFilterDataByHeading(heading) {
    const filter = filterListData.find((item) => item.filter_heading === heading);
    return filter ? filter.filter_data : [];
  }

  function handleBrandImageClick(brandName, index) {
    // Retrieve filter data for "brand" heading
    const brandFilterData = getFilterDataByHeading("brand");
    // Retrieve the "data" field from filter_data
    const brandDataList = brandFilterData.map((item) => item.data?.toString()?.toLowerCase());
    scrollToProductCard();
    if (Array.from(brandDataList).includes(brandName)) {
      const obj = brandFilterData.find((obj) => obj.data?.toString()?.toLowerCase() === brandName);
      handleSingleFilterSelection(obj);
    } else {
      const obj = {
        type: `brand`,
        data: brandName,
        searchQ: brandName,
        id: `${brandName}_brand_${index}`,
      };
      handleSingleFilterSelection(obj);
    }
  }

  function handleProcessorImageClick(processorName, index) {
    // Retrieve filter data for "processor" heading
    const processorFilterData = getFilterDataByHeading("processor");
    // Retrieve the "data" field from filter_data
    const processorDataList = processorFilterData.map((item) => item.data?.toString()?.toLowerCase());
    scrollToProductCard();
    const regex = new RegExp(processorName, "i");
    if (Array.from(processorDataList).some((e) => regex.test(e))) {
      const obj = processorFilterData.find((obj) => {
        if (regex.test(obj.data)) return obj;
        else return null;
      });
      obj && handleSingleFilterSelection(obj);
    } else {
      const obj = {
        type: `productInfo.processor`,
        data: processorName,
        searchQ: processorName,
        id: `${processorName}_processor_${index}`,
        dynamic: true,
      };
      handleSingleFilterSelection(obj);
    }
  }

  function handleLaptopTypeImageClick(typeName, index) {
    // Retrieve filter data for "processor" heading
    const typeFilterData = getFilterDataByHeading("processor");
    // Retrieve the "data" field from filter_data
    const typeDataList = typeFilterData.map((item) => item.data?.toString()?.toLowerCase());
    scrollToProductCard();
    const regex = new RegExp(typeName, "i");
    if (Array.from(typeDataList).some((e) => regex.test(e))) {
      const obj = typeFilterData.find((obj) => {
        if (regex.test(obj.data)) return obj;
        else return null;
      });
      obj && handleSingleFilterSelection(obj);
    } else {
      const obj = {
        type: `productInfo.laptop_type`,
        data: typeName,
        searchQ: typeName,
        id: `laptop_type_${index}`,
        dynamic: true,
      };
      handleSingleFilterSelection(obj);
    }
  }

  const screenSize = useScreenSize();

  useEffect(() => {
    if (!location.pathname.includes("/search")) {
      let topBanners = homePageSections.find((section) => section.sectionType === "scroll_banner");
      if (!topBanners) return;
      let banners = topBanners.products;
      banners = banners.filter((banner) => banner.title.split("_")[1] === location.pathname.split("=")[1].replace(/%20/g, " "));
      setCarouselData(banners);
      let lowerBanner = homePageSections.filter((section) => section.sectionType === "static_deals" && section.sectionHeading.split("_")[1] === location.pathname.split("=")[1].replace(/%20/g, " "));
      setLowerBanners(lowerBanner[0]?.products);
    }
  }, [homePageSections, location]);

  return (
    <div className="pageWrapper">
      <SEO seoData={seoData} />
      {filterSelected.length === 0 && (
        <>
          {(location && location.pathname === "/Category=all") || location.pathname.includes("search") ? (
            <Link className="all-categories-banner offer-banners" to={`/My-Offers`}>
              <img
                src="https://olineo-banner-prod.s3.ap-south-1.amazonaws.com/Category_Page_Banners/Realme/4.jpg"
                loading="lazy"
                alt="main_banner"
                title="My Offers"
                style={{ objectFit: `${isMobile && "cover"}`, minHeight: "90px", maxWeight: "450px", cursor: "pointer" }}
              />
            </Link>
          ) : (
            <Carousel interval={2000} className="w-100">
              {carouselData ? (
                carouselData?.map((item, index) => (
                  <Carousel.Item key={index}>
                    <Link to={item.targetUrl}>
                      <div className="all-categories-banner">
                        <img src={item.imgUrl} loading="lazy" alt="main_banner_smartphone" style={{ objectFit: `${isMobile && "cover"}`, minHeight: "90px", maxWeight: "450px" }} />
                      </div>
                    </Link>
                  </Carousel.Item>
                ))
              ) : (
                <SkeletonElement type={"productBanner"} style={{ height: "280px" }} />
              )}
            </Carousel>
          )}
        </>
      )}

      <div
        className="mainContainerWrapper"
        style={{
          marginTop: location.pathname === "/search" ? "var(--pageMarginTop)" : "10px",
          width: "100%",
        }}
      >
        <div className="all-categories-section-1">
          {location && location?.pathname !== "/search" && (
            <div
              style={{
                display: isMobile ? "none" : "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-evenly",
                marginBottom: "10px",
                gap: "5px",
                padding: !isMobile ? "0 5px" : "0px",
              }}
            >
              {lowerBanners?.map((banner, index) => (
                <Link to={banner.targetUrl} key={index} style={{ width: index === 0 ? "30%" : "70%" }}>
                  <img
                    style={{
                      width: "100%",
                      height: "300px",
                      objectFit: "cover",
                      borderRadius: !isMobile ? "10px" : "0px 10px 10px 0px",
                    }}
                    src={banner.image}
                    alt="leftbanner"
                  />
                </Link>
              ))}
            </div>
          )}
        </div>

        {/* {filterListData?.find((filterData) => filterData.filter_heading === "price")?.filter_data?.length ? (
          <div id="all-mid-heading">
            <span>{t("ShopbyBudget")}</span>
          </div>
        ) : null} */}
        {loading ? (
          <div className="loader-div rounded d-flex flex-column">
            {/* <CircularProgress color="inherit" /> */}
            <img src={loadingImg} alt="loading_tab" className="loading-image rounded" />
            Loading...
          </div>
        ) : (
          <div>
            <div className={`main_Content_Show ${toggleFilter && !matches ? "main_Content_Hide" : ""}`}>
              <div className="desk_Page_Wrapper_res">
                {isMobile ? (
                  <div>
                    <Drawer varaint="temporary" anchor="right" open={Boolean(anchorEl)} onClose={handleClose} PaperProps={{ style: { zIndex: 1200 } }}>
                      <DrawerSection
                        isMobile={isMobile}
                        onClick={handleClose}
                        value={priceRange}
                        handleChange={handleChange}
                        filterListData={filterListData}
                        handleAll2={handleAll2}
                        filterSelected={filterSelected}
                        handlePriceRangeSubmit={handlePriceRangeSubmit}
                        minMaxPrice={minMaxPrice}
                        sliderStep={step}
                        handlePriceReset={handlePriceReset}
                        headingName={headingName}
                        priceRange={priceRange}
                      />
                    </Drawer>
                  </div>
                ) : (
                  <div className="flex-container" style={{ width: "12%" }}>
                    <div className="w-100 drawerSection">
                      <DrawerSection
                        filterListData={filterListData}
                        isMobile={isMobile}
                        value={priceRange}
                        handleChange={handleChange}
                        handleAll2={handleAll2}
                        filterSelected={filterSelected}
                        handlePriceRangeSubmit={handlePriceRangeSubmit}
                        minMaxPrice={minMaxPrice}
                        sliderStep={step}
                        handlePriceReset={handlePriceReset}
                        headingName={headingName}
                        priceRange={priceRange}
                      />
                    </div>
                  </div>
                )}

                <div className="scrollable-product">
                  <div className="order_Page_Right_res">
                    {searchedProduct.no_of_products > 0 ? (
                      <>
                        <div className="right-wrapper">
                          <div className="SpotLight_res">
                            <div>
                              <span>{t("Spotlight")}</span>
                            </div>
                            <div className="SpotLight_res-items" onClick={handleOpen}>
                              {isMobile ? <FilterAltOutlinedIcon /> : <SegmentIcon fontSize="large" />}
                              {isMobile ? (
                                <span>{t("Filter")}</span>
                              ) : (
                                <span
                                  ref={anchorRef}
                                  id="composition-button"
                                  aria-controls={open ? "composition-menu" : undefined}
                                  aria-expanded={open ? "true" : undefined}
                                  aria-haspopup="true"
                                  onClick={handleToggle}
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("sortAndFilter.sort-by")}
                                </span>
                              )}
                              <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal style={{ zIndex: "100000" }}>
                                {({ TransitionProps, placement }) => (
                                  <Grow
                                    {...TransitionProps}
                                    style={{
                                      transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                                    }}
                                  >
                                    <Paper>
                                      <ClickAwayListener onClickAway={handleClosePOP}>
                                        <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
                                          <MenuItem onClick={(event) => handleClosePOP(event, "")}>
                                            <input type="radio" style={{ accentColor: "orange" }} checked={sorting === "" && true} /> <span>{t("default")}</span>
                                          </MenuItem>
                                          <MenuItem onClick={(event) => handleClosePOP(event, "ascending")}>
                                            <input type="radio" style={{ accentColor: "orange" }} checked={sorting === "ascending" && true} /> <span>{t("sortAndFilter.price-ascending")}</span>
                                          </MenuItem>
                                          <MenuItem onClick={(event) => handleClosePOP(event, "descending")}>
                                            <input type="radio" style={{ accentColor: "orange" }} checked={sorting === "descending" && true} /> <span>{t("sortAndFilter.price-descending")}</span>
                                          </MenuItem>
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Paper>
                                  </Grow>
                                )}
                              </Popper>
                            </div>
                          </div>
                          {/* {notFound !== "" && (
                            <div className="empty_order_sec_res">
                              <p className="empty_order_text_res">No {notFound} Products Found</p>
                              <button type="submit" className="submit-button" onClick={() => setFilterSelected([])}>
                                <p>Clear Filters</p>
                              </button>
                            </div>
                          )} */}
                          <div className={"Product_Category_Container_res"}>
                            {searchedProduct.products.map((product, index) => (
                              <ProductCard
                                key={`product-${index}`}
                                id={product._id}
                                productImg={product.images?.length > 0 ? product.images[0] : null}
                                productName={product.title}
                                productDescription={product?.description?.length > 0 ? product?.description[0]?.split(" ")?.slice(0, 18)?.join(" ") : null}
                                productRating={product?.rating ?? 0}
                                productPriceObj={product.price}
                                productDiscountObj={product.discount}
                                productSlug={product.slug}
                                wishlist={userWishlist.wishlist_items?.some((obj) => obj._id?.toString() === product._id?.toString())}
                                cart={cartArray?.no_of_carts > 0 && cartArray?.cart.some((obj) => obj._id?.toString() === product._id?.toString())}
                                classification={product?.classification}
                                preBookData={product?.preBook}
                              />
                            ))}
                          </div>
                          <div className="pagination-con">
                            <Pagination
                              sx={{
                                color: "primary.main",
                              }}
                              page={currentPage}
                              defaultPage={1}
                              onChange={handlePageChange}
                              count={Math.ceil(totalProducts / productsPerPage)}
                              siblingCount={isMobile ? 0 : 1}
                              variant="outlined"
                              shape="rounded"
                              size="large"
                            />
                          </div>
                          <div className="pagination_Container"></div>
                        </div>
                      </>
                    ) : (
                      <div className="empty_order_sec_res">
                        <p className="empty_order_text_res">{t("sortAndFilter.no-product")}</p>
                        <button type="submit" className="submit-button" onClick={() => nav("/")}>
                          <p>{t("back-to-home")}</p>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCategory;
