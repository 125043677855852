import React, { useEffect, useState } from "react";
import useUserCtx from "../hooks/useUserCtx";
import Loader from "../components/Loader/Loader";
import "./coupon.css";
import Coupon from "../components/Coupon";
import { useMediaQuery } from "@mui/material";

function UserWallet() {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState([]);
  const { walletData } = useUserCtx();

  const isMobile = useMediaQuery(`(max-width: 500px)`);

  useEffect(() => {
    if (walletData?.length > 0) {
      console.log("walletData ", walletData);
      setCoupons(walletData);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [walletData]);

  return (
    <div className="homepage_wrapper">
      {loading ? (
        <Loader />
      ) : (
        <div className="d-flex " style={{ flexWrap: "wrap", gap: "10px", padding: `${!isMobile ? "10px 30px" : "30px 20px"}`, justifyContent: `${isMobile ? "center" : "flex-start"}` }}>
          {coupons.map((item, i) => (
            <Coupon key={i} text={item.text} value={item.value} status={item?.userStatus[0].status} isMobile={isMobile} />
          ))}
        </div>
      )}
    </div>
  );
}

export default UserWallet;
{
  /* <div className="couponeCard " style={{ width: "350px", paddingTop: "40px", paddingBottom: "40px" }}>
              <img src="https://beta.olineo.in/backendImages/couponbg.png" />
              <div className="coupnImage d-flex ">
                <div>
                  <img src={logo} style={{ width: "100%" }} />
                  <div>
                    <img src={star} />
                    <p className="couponText">{item.text}</p>
                    <img src={star} />
                  </div>
                </div>
                <div className="middleLine"></div>
                <div>
                  <img src={congratulations} />
                  <p>Cash Back Of {currencyFormat(item.value)} *</p>
                </div>
              </div>
            </div> */
}
